import {useEffect, useState} from 'react';

import {REMOVE_TAG_BUTTON_CLASS} from '../../../constants/constants';

export const useAvatarMultiSelectOperation = (
    value: string[] | [],
    containerRef: React.RefObject<any>
) => {
    const [operationCompleted, setOperationCompleted] = useState(false);
    const updateTabIndex = () => {
        const removeTagButtons = containerRef.current.querySelectorAll(
            `button.${REMOVE_TAG_BUTTON_CLASS}`
        );
        if (removeTagButtons) {
            removeTagButtons.forEach((btn: any) => {
                if (btn.closest('div[aria-hidden="true"]')) {
                    btn.setAttribute('tabIndex', -1);
                } else {
                    btn.setAttribute('tabIndex', 0);
                }
            });
        }
        setOperationCompleted(true);
    };

    useEffect(() => {
        if (value?.length > 0 && containerRef?.current) {
            updateTabIndex();
        }
    }, [value, containerRef]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(updateTabIndex);

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current);
            }
        };
    }, []);

    return {operationCompleted};
};
