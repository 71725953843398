import {Avatar, Tooltip} from '@citrite/citrix-ui';

import {AVATAR_LIMIT} from '../../../../constants/constants';
import {useTableContext} from '../../context/TableContext';
import {stringToArray} from '../../util';
import {AvatarFieldProps} from '../Fields';
import {AvatarGroupContainer, AvatarsContainer, AvatarText} from '../Fields.styled';

export const AvatarField = ({fieldValue}: AvatarFieldProps) => {
    const {userList = {}} = useTableContext();
    const overflowCount = fieldValue?.length - AVATAR_LIMIT;

    const avatarValue = stringToArray(fieldValue);

    const getInitials = (fullname: string) => {
        const names = fullname.split(' ');
        let initials = names[0].substring(0, 1).toLocaleUpperCase();
        names.length > 1
            ? (initials += names[names.length - 1].substring(0, 1).toLocaleUpperCase())
            : (initials += '');
        return initials;
    };

    const getAvatarUrl = (userId: string) => {
        const hostnameParts = window.location.hostname.split('.');
        hostnameParts[0] = 'sf-assets';
        return `https://${hostnameParts.join('.')}/avatar/${userId}`;
    };

    return (
        <AvatarGroupContainer className="avatar-group">
            <AvatarsContainer>
                {avatarValue &&
                    Array.isArray(avatarValue) &&
                    avatarValue.slice(0, AVATAR_LIMIT).map((user: string) => {
                        if (userList[user])
                            return (
                                <Tooltip
                                    hoverTrigger={
                                        <Avatar
                                            label={getInitials(userList[user].FullName)}
                                            src={getAvatarUrl(userList[user].Id)}
                                            size={32}
                                        />
                                    }
                                    placement="top"
                                    key={userList[user]?.Id}
                                >
                                    {userList[user]?.FullName}
                                </Tooltip>
                            );
                        return null;
                    })}
            </AvatarsContainer>
            {avatarValue.length === 1 && (
                <AvatarText>{userList[avatarValue[0]]?.FullName}</AvatarText>
            )}
            {overflowCount > 0 && (
                <Tooltip
                    hoverTrigger={
                        <AvatarText>
                            {'+'}
                            {overflowCount}
                        </AvatarText>
                    }
                    placement="top"
                >
                    {avatarValue.slice(AVATAR_LIMIT).map((user: string) => (
                        <div key={userList[user]?.Id}>{userList[user]?.FullName}</div>
                    ))}
                </Tooltip>
            )}
        </AvatarGroupContainer>
    );
};
