import {Typography} from 'antd';
import {useEffect, useMemo, useState} from 'react';

import {transformNodeToText} from '../../../../schema-components/antd/edit/RichText/helper';
import {InputFieldProps} from '../Fields';

const {Text} = Typography;

export const RichTextField = ({fieldValue}: InputFieldProps) => {
    const [renderText, setRenderText] = useState('');

    const truncateString = (val: string, maxLength: number) =>
        val.length > maxLength ? val.substring(0, maxLength) + '...' : val;

    const fetchText = useMemo(() => {
        return () => {
            if (!fieldValue) return;
            const transformedStr = truncateString(transformNodeToText(fieldValue), 100);
            setRenderText(transformedStr);
        };
    }, [fieldValue]);

    useEffect(() => {
        fetchText();
    }, [fetchText]);

    return <Text ellipsis={{tooltip: `${renderText}`}}>{renderText}</Text>;
};
