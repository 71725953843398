import {PiletApi} from '@sharefiledev/sharefile-appshell';
import React from 'react';

export interface AppContext {
    piletApi: PiletApi;
}

const AppContext = React.createContext<AppContext>({piletApi: {} as any});

export interface AppContextProviderProps {
    piletApi: PiletApi;
    children: React.ReactNode;
}
export const AppContextProvider: React.FC<AppContextProviderProps> = ({piletApi, children}) => (
    <AppContext.Provider value={{piletApi}}>{children}</AppContext.Provider>
);

export const useAppContext = () => React.useContext(AppContext);
