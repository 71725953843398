import {UsersEntity} from '@citrite/sf-api';
import {addUsersToProject, ProjectUserRole} from '@sharefiledev/projects-pilet';
import {RID} from '@sharefiledev/sfplugin-resource-id';
import {User} from '@sharefiledev/sharefile-appshell';

export const getUser = (x: string) => UsersEntity.get(x).execute();

export const addUsersToProjectFromRelatedResources = async (
    projectId: string,
    newUserIds: string[]
) => {
    if (RID.parse(projectId).entityType === 'project') {
        const newUsers: User[] = await Promise.all(newUserIds.map(getUser));

        // Add the user as a counterpart if the role is 'Employee'
        const employeeUsers: User[] = newUsers.filter((user: User) =>
            (user.Roles ?? []).includes('Employee')
        );

        if (employeeUsers.length > 0) {
            await addUsersToProject(projectId, employeeUsers, ProjectUserRole.Counterpart);
        }
    }
};
