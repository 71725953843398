import {getUsersWithPermission} from '../api/dcsApi';
import {UserPermission, UserPermissionResponse} from '../model/types';
import {t} from '../utils';
import {SliceStateCreator, UserSlice} from './types';

export const usersSliceInitialState = {
    userList: {},
    userPermissions: [],
};

export const createUsersSlice: SliceStateCreator<UserSlice> = (set: any) => ({
    ...usersSliceInitialState,

    loadUserList: async () => {
        set({userList: []});
    },

    loadUserPermission: async (
        containerId: string,
        loggedInUser: string | undefined,
        notification = undefined
    ) => {
        try {
            const usersRecord: UserPermissionResponse = await getUsersWithPermission(containerId);
            set({userList: usersRecord.users});
            const userPermissions: string[] =
                (loggedInUser &&
                    usersRecord?.users[loggedInUser as keyof UserPermission]?.permissions) ||
                [];
            set({userPermissions: userPermissions});
        } catch {
            notification &&
                notification(
                    'error',
                    t('dynamic-components:apiResponseNotification.notifyApiFailure')
                );
        }
    },

    refreshUserList: async (containerId: string, notification = undefined) => {
        try {
            const usersRecord: UserPermissionResponse = await getUsersWithPermission(containerId);
            set({userList: usersRecord.users});
        } catch {
            notification &&
                notification(
                    'error',
                    t('dynamic-components:apiResponseNotification.notifyApiFailure')
                );
        }
    },
});
