import styled from '@emotion/styled';
import {sizes} from '@sharefiledev/antd-config';

export const ColorTagItemStyle = styled.div`
    display: flex;
    align-items: center;
    border-radius: calc(${sizes.XXS} / 2);
    cursor: pointer;
    min-width: 140px;
    justify-content: space-between;

    .color-text {
        margin-left: calc(${sizes.XS} / 2);
    }
`;

export const CircleButton = styled.button`
    width: ${sizes.MS};
    height: ${sizes.MS};
    display: flex;
    border-radius: 50%;
    padding: 0;
    border: none;
`;
