import {CheckOutlined} from '@ant-design/icons';
import {Dropdown, MenuProps, Row} from 'antd';
import {useEffect, useRef, useState} from 'react';

import {COLORS} from '../../constants/constants';
import {CircleButton, ColorTagItemStyle} from './ColorPicker.styled';

type Props = {
    value: string;
    onSelect: (value: string) => void;
};

export const ColorPicker = ({value, onSelect}: Props) => {
    const [bgColor, setBgColor] = useState(value ? value : 'default');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setBgColor(value);
    }, [value]);

    const onClick: MenuProps['onClick'] = (e) => {
        const color = e.key;
        setBgColor(color);
        if (color !== value) {
            onSelect(color);
        }

        setDropdownOpen(false);
        setTimeout(() => {
            dropdownRef.current?.querySelector('button')?.focus();
        }, 0);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        const menuItems = document.querySelectorAll<HTMLElement>('.ant-dropdown-menu-item');
        if (!menuItems || menuItems.length === 0) return;

        const currentIndex = Array.from(menuItems).findIndex(
            (item) => item === document.activeElement
        );
        let nextIndex: number;

        switch (e.key) {
            case 'ArrowDown':
                nextIndex = (currentIndex + 1) % menuItems.length;
                break;
            case 'ArrowUp':
                nextIndex = (currentIndex - 1 + menuItems.length) % menuItems.length;
                break;
            case 'Tab':
                e.preventDefault();
                nextIndex = e.shiftKey
                    ? (currentIndex - 1 + menuItems.length) % menuItems.length
                    : (currentIndex + 1) % menuItems.length;
                break;
            default:
                return;
        }

        menuItems[currentIndex]?.setAttribute('tabindex', '-1');
        menuItems[nextIndex]?.setAttribute('tabindex', '0');
        menuItems[nextIndex]?.focus();
    };

    const handleFocus = (e: React.FocusEvent<HTMLButtonElement>) => {
        e.currentTarget.style.borderColor = '#6356f6';
        e.currentTarget.style.boxShadow = '0 0 0 4px #DEDAFB';
        e.currentTarget.style.outline = '0';
    };

    const handleBlur = (e: React.FocusEvent<HTMLButtonElement>) => {
        e.currentTarget.style.borderColor = 'transparent';
        e.currentTarget.style.boxShadow = '0 0 0 4px transparent';
        e.currentTarget.style.outline = '0';
    };

    const items: MenuProps['items'] = Object.keys(COLORS).map((color: string) => ({
        label: (
            <>
                <ColorTagItemStyle
                    role="menuitem"
                    tabIndex={-1}
                    aria-label={COLORS[color].value}
                    data-color={color}
                    onKeyDown={handleKeyDown}
                >
                    <Row>
                        <CircleButton
                            style={{
                                backgroundColor: COLORS[color].accentColor,
                                color: COLORS[color].accentColor,
                                borderColor: COLORS[color].accentColor,
                            }}
                        />
                        <span className="color-text">{COLORS[color].value}</span>
                    </Row>
                    {bgColor === color && <CheckOutlined />}
                </ColorTagItemStyle>
            </>
        ),
        key: color,
    }));

    useEffect(() => {
        if (dropdownOpen) {
            const menuItems = document.querySelectorAll<HTMLElement>('.ant-dropdown-menu-item');
            if (menuItems && menuItems.length > 0) {
                menuItems[0].tabIndex = 0;
                menuItems[0].focus();
            }
        }
    }, [dropdownOpen]);

    return (
        <div ref={dropdownRef}>
            <Dropdown
                autoFocus
                menu={{items, onClick, selectable: true, defaultSelectedKeys: [bgColor]}}
                trigger={['click']}
                arrow={{pointAtCenter: true}}
                onOpenChange={(open) => setDropdownOpen(open)}
            >
                <CircleButton
                    id="dropdownMenu-color-btn"
                    aria-label={COLORS[bgColor].value}
                    style={{
                        backgroundColor: COLORS[bgColor].accentColor,
                        color: COLORS[bgColor].accentColor,
                        borderColor: COLORS[bgColor].accentColor,
                    }}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
            </Dropdown>
        </div>
    );
};
