import {colorPalette} from '@sharefiledev/antd-config';
import {Col, Row} from 'antd';

import {FileBrowserCounter} from '../../../../schema-components/plugins/file-browser-plugin/FileBrowserCounter';
import {t} from '../../../../utils';
import {Icons} from '../../../../utils/icons/icon';
import {FileFieldProps} from '../Fields';

export const FileField = ({fieldValue, recordId}: FileFieldProps) => {
    const fileRID = `${recordId}/${fieldValue}`;
    return (
        <Row>
            <Col>
                <Icons.Paperclip size={24} color={colorPalette.neutral5} />
            </Col>
            <Col>
                {fieldValue ? (
                    <FileBrowserCounter containerRID={fileRID} />
                ) : (
                    <div>{t('dynamic-components:fileBrowserPlugin.empty.cellText')}</div>
                )}
            </Col>
        </Row>
    );
};
