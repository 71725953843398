import {layout} from '@citrite/citrix-ui';
import styled from '@emotion/styled';
import {InputNumber} from 'antd';

export const AvatarText = styled.span`
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
`;

export const StyledTagRemoveBtn = styled.button`
    border: none;
    background: transparent;
    cursor: pointer;
    padding-inline: 0;
`;

export const StyledSpanVerticalAlign = styled.span`
    vertical-align: middle;
`;

export const AssigneeMultiUserFieldWrapper = styled.div<{isEditable: boolean}>`
    pointer-events: ${(prop) => (prop.isEditable ? 'auto' : 'none')};
`;

export const LinkBox = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    font-size: 14px;
`;

export const FileTableWrapper = styled.div<{borderColor: string}>`
    // override margin and border for storage plugin

    & > div > div > div:first-of-type {
        margin-top: 4px;
        margin-bottom: 4px;
        border-bottom: 1px solid ${(props) => props.borderColor || '#000'};
    }
`;

export const fileTableStyles: React.CSSProperties = {
    padding: '4px',
    backgroundColor: 'transparent',
};

export const AvatarGroupContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${layout.smallSpace};
`;

export const AvatarsContainer = styled.div`
    display: flex;
    flex-direction: row;
    > span:not(:first-of-type) {
        margin-left: -8px;
    }
`;

export const StyledInputNumber = styled(InputNumber)`
    width: 100%;
    height: 40px;
    text-align-last: right;
    align-items: center;
    margin-top: 16px;
`;
