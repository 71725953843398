import {
    ContextMenuItem,
    CustomizationData,
    Events,
    storageExtensionSlots,
    StorageFileTableParams,
} from '@sharefiledev/storage-plugin-pilet';

import {useAppContext} from '../../../AppContext';
import {pendoSendTrackEvent, trackUploadFileSuccess} from '../../../tracking';
import {getLanguage, t} from '../../../utils';
import {usePluginEventListener} from '../../../utils/hooks/events/pluginEvents';

interface Props {
    containerRID: string;
    uploadBtn?: {
        size?: 'large' | 'middle' | 'small';
        type?: 'default' | 'primary' | 'link' | 'text' | 'dashed';
        label?: string;
    };
    fileUploadComplete?: () => void;
    fileDelete?: () => void;
}

export const FileBrowserTable = (props: Props) => {
    const userLanguage = getLanguage();
    const {
        piletApi: {Extension},
    } = useAppContext();

    //pendo event for file successful upload
    const fileUploadSuccessCallback = () => {
        pendoSendTrackEvent(trackUploadFileSuccess);
        props.fileUploadComplete && props.fileUploadComplete();
    };

    const fileDeleteSuccessCallback = () => {
        props.fileDelete && props.fileDelete();
    };

    usePluginEventListener(Events.UploadFileCompleteChannel, fileUploadSuccessCallback);
    usePluginEventListener(Events.DeleteFileChannel, fileDeleteSuccessCallback);

    const configureContextMenu = () => {
        const uploadMenuItem: ContextMenuItem = {
            menuType: 'upload',
            type: props.uploadBtn?.type ?? 'default',
            size: props.uploadBtn?.size ?? 'middle',
        };

        const downloadMenuItem: ContextMenuItem = {
            menuType: 'download',
            type: 'default',
        };

        const deleteMenuItem: ContextMenuItem = {
            menuType: 'delete',
            type: 'default',
        };
        return [downloadMenuItem, deleteMenuItem, uploadMenuItem];
    };

    const customization: CustomizationData = {
        columnsList: [],
        contextMenu: configureContextMenu(),
        disableColumnSorting: true,
        disableSelection: true,
        disableTableHeader: true,
        pagination: {
            onlyDisplayFirstPage: true,
            allowedPageSizes: [10, 20, 25, 50, 75, 100],
            defaultPageSize: 100,
        },
        labels: [
            {
                key: 'upload.upload',
                value: {
                    [userLanguage]:
                        props.uploadBtn?.label ??
                        t('dynamic-components:fileBrowserPlugin.uploadBtnDefault'),
                },
            },
            {
                key: 'upload.empty.title',
                value: {[userLanguage]: t('dynamic-components:fileBrowserPlugin.empty.title')},
            },
            {
                key: 'upload.empty.message',
                value: {[userLanguage]: ' '},
            },
        ],
    };

    const filesTableParams: StorageFileTableParams = {
        containerRID: props.containerRID,
        customizationData: customization,
    };

    return <Extension name={storageExtensionSlots.FilesTable} params={filesTableParams} />;
};
