import styled from '@emotion/styled';

export const IconStyle = styled.div`
    margin-right: 10px;
    display: flex;
    align-items: center;
`;

export const LabelStyle = styled.span`
    display: flex;
    align-items: center;
`;
