import styled from '@emotion/styled';
import {colorPalette} from '@sharefiledev/antd-config';

export const HeaderText = styled.span`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    font-style: normal;
`;

export const DataTableStyles = styled.div`
    .open-btn {
        display: none;
        align-items: center;
        margin-right: 4px;
    }
    .ant-table-placeholder {
        display: flex;
        justify-content: center;
        height: 200px;
        align-items: center;
        .ant-table-cell {
            border: none;
        }
    }
    th: hover {
        background: #f3f3f3 !important;
        color: #000;
        cursor: pointer;
        .sort-image {
            display: contents;
        }
    }

    .ant-table-footer {
        display: flex;
        background: white !important;
        border-left: none !important;
        border-right: none !important;
        border-top: 1px solid #d9d9d9 !important;
        border-bottom: 1px solid #d9d9d9 !important;
        border-radius: 0 !important;
        height: 48px;
        padding: 4px 16px;
        .footer-style {
            display: flex;
            width: 100%;
            align-items: center;
            cursor: pointer;
            position: relative;
            right: 12px;
            .add-row {
                color: ${colorPalette.lavender6};
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                margin-left: 4px;
            }
        }
    }

    #newRow-btn {
        width: fit-content;
    }

    .ant-table-cell-row-hover {
        background: #f3f3f3 !important;
        .open-btn {
            display: block;
        }
    }
    .ant-table-container {
        border-inline-start: none !important;
        border-top: 1px solid #d9d9d9 !important;
        border-start-start-radius: 0;
        border-start-end-radius: 0;
    }
    .ant-table-header > table > thead > tr > th {
        border-inline-end: 1px solid #d9d9d9 !important;
        border-bottom: 1px solid #d9d9d9 !important;
    }

    .ant-table-header > table > thead > tr > th:last-child {
        border-inline-end: none !important;
    }

    .ant-table-thead {
        .ant-table-cell {
            padding: 0 16px;
            height: 30px;
            background: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            color: #555;
        }
    }

    .ant-editable-cell {
        margin-left: 2px;
    }

    .ant-table-row {
        td:last-child {
            .textinput-field {
                right: 220px !important;
            }
        }

        td:first-child .table-cell.edit .open-btn {
            display: none;
        }

        .ant-table-cell:not(:last-child) {
            border-inline-end: 1px solid #d9d9d9 !important;
        }

        .ant-table-cell {
            height: 48px;
            border-bottom: 1px solid #d9d9d9 !important;
            padding: 0px;
            display: flex;
            align-items: center;
            .table-cell {
                height: 100%;
                width: 100%;
            }
            overflow: hidden;
            white-space: nowrap;
            .select-field,
            .date-field {
                width: 100%;
                z-index: 1;
                bottom: 4px;
            }
            // .currency-field,
            .numeric-field {
                position: relative;
                bottom: 6px;
            }
            .textarea-field,
            .textinput-field,
            .currency-field {
                position: relative;
                z-index: 1;
            }
            .multi-select {
                display: flex;
                width: 100%;

                .ant-select {
                    width: 100%;
                }
            }
            .contact-multi-select {
                position: relative;
                z-index: 1;
                bottom: 5px;
                right: 16px;
            }
            .avatar-group {
                width: 100%;
                span {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .ant-anchor-link-title {
                color: #1677ff;
            }
            .link-field {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-decoration: underline;
                color: #1677ff;
            }
        }

        .editable-cell-value-wrap {
            box-sizing: border-box;
            height: 100%;
            width: 100%;
            align-items: center;
            overflow: hidden;
            display: flex;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: keep-all;
            // padding: 4px 0;
            .overdue-badge {
                position: relative;
                bottom: 8px;
            }
            span:first-child {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .editable-cell-value-wrap.view,
        .editable-cell-value-wrap.edit .padded-row {
            padding: 0px 16px;
        }
        .editable-cell-value-wrap.edit:not(:has(.padded-row)) {
            padding: 2px;
        }
    }

    .ant-input-number-input-wrap {
        padding-right: 12px;
    }
`;

export const addColumnBtnStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    color: colorPalette.lavender6,
    lineHeight: 16,
    height: 15,
};
