import {FieldsEnum} from '../../../constants/fields';
import {Layout, UIMapperType, Variant} from '../../../engine/types';
import {AvatarUserMultiSelectField} from './Edit/Avatar';
import {CurrencyInputField} from './Edit/Currency';
import {DatePickerField} from './Edit/Date';
import {FileInputField} from './Edit/File';
import {InputField} from './Edit/InputField';
import {NumberField} from './Edit/Number';
import {RichTextInputField} from './Edit/RichText';
import {SingleSelect} from './Edit/Select';
import {TextAreaField} from './Edit/TextArea';
import {AvatarField} from './View/Avatar';
import {CurrencyField} from './View/Currency';
import {DateText} from './View/Date';
import {FileField} from './View/File';
import {LinkField} from './View/Link';
import {RichTextField} from './View/RichText';
import {SingleSelectText} from './View/Select';
import {TextField} from './View/TextField';

const FieldMapper: UIMapperType = {
    [Layout.Table]: {
        [Variant.View]: {
            [FieldsEnum.TextInputField]: TextField,
            [FieldsEnum.TextAreaField]: TextField,
            [FieldsEnum.Select]: SingleSelectText,
            [FieldsEnum.DateField]: DateText,
            [FieldsEnum.DateOverdueField]: DateText,
            [FieldsEnum.AccountUserMultiSelect]: AvatarField,
            [FieldsEnum.NumericField]: TextField,
            [FieldsEnum.LinkField]: LinkField,
            [FieldsEnum.CurrencyField]: CurrencyField,
            [FieldsEnum.FileStorageField]: FileField,
            [FieldsEnum.RichTextEditorField]: RichTextField,
        },
        [Variant.Edit]: {
            [FieldsEnum.TextInputField]: InputField,
            [FieldsEnum.TextAreaField]: TextAreaField,
            [FieldsEnum.Select]: SingleSelect,
            [FieldsEnum.SingleSelectField]: SingleSelect,
            [FieldsEnum.DateField]: DatePickerField,
            [FieldsEnum.DateOverdueField]: DatePickerField,
            [FieldsEnum.AccountUserMultiSelect]: AvatarUserMultiSelectField,
            [FieldsEnum.NumericField]: NumberField,
            [FieldsEnum.LinkField]: InputField,
            [FieldsEnum.CurrencyField]: CurrencyInputField,
            [FieldsEnum.FileStorageField]: FileInputField,
            [FieldsEnum.RichTextEditorField]: RichTextInputField,
        },
    },
};

export default FieldMapper;
