import {Input} from 'antd';

import {useFieldOperations} from '../../hooks/useFieldOperations';
import {InputFieldProps} from '../Fields';

const {TextArea} = Input;
export const TextAreaField = ({
    forwardedRef,
    fieldValue,
    onChange,
    required,
    validations,
}: InputFieldProps) => {
    const {inputValue, handleOnChange, isError, handleOnBlur} = useFieldOperations(
        fieldValue,
        required,
        validations,
        onChange
    );
    return (
        <TextArea
            value={inputValue}
            onChange={(event) => handleOnChange(event.target.value)}
            onBlur={handleOnBlur}
            ref={forwardedRef}
            status={isError ? 'error' : ''}
        />
    );
};
