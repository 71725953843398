import {createTable, deleteTable, getTable, updateTable} from '../api/dcsApi';
import {
    pendoSendTrackEvent,
    trackCreateDataTable,
    trackdeleteDataTable,
    trackTypeColumnAdded,
    trackUpdateDataTable,
    trackUpdateDataTableName,
} from '../tracking';
import {t} from '../utils';
import {newColumnAdded, setFrozen} from '../utils/utils';
import {SchemaSlice, SliceStateCreator} from './types';

export const schemaSliceInitialState = {
    table: {},
    columnField: {
        value: {
            label: '',
            name: '',
            component: 'TextInputField',
            validations: {type: 'string'},
            attributes: {},
        },
        isValid: true,
        isEdit: false,
    },
    isManageColumnModalOpen: false,
    reloadTabs: false,
    // isProjectFrozen: false,
};

export const createSchemaSlice: SliceStateCreator<SchemaSlice> = (set: any, get: any) => ({
    ...schemaSliceInitialState,

    setTable: (tableData: any) => {
        set((state: any) => ({table: {...state.table, ...tableData}}));
    },
    loadTable: async (tableRID: string, notification = undefined, isProjectFrozen = false) => {
        try {
            let response: any = await getTable(tableRID);
            response = isProjectFrozen ? setFrozen(response) : response;

            set({table: response});
            return response;
        } catch (err) {
            notification && notification('error', `message failed ${err}`);
        }
    },

    setField: (value: any) => {
        set((state: any) => ({columnField: {...state.columnField, ...value}}));
    },

    createTable: async (tableData: any, notification = undefined) => {
        const {setButtonLoader} = get();
        try {
            setButtonLoader(true);
            const response = await createTable(tableData);
            notification &&
                notification(
                    'success',
                    t('dynamic-components:apiResponseNotification.notifyCreateTableSuccess')
                );
            pendoSendTrackEvent(trackCreateDataTable);
            return response;
        } catch (err) {
            notification &&
                notification(
                    'error',
                    t('dynamic-components:apiResponseNotification.notifyApiFailure')
                );
        } finally {
            setButtonLoader(false);
        }
    },

    addOrUpdateColumnField: async (schemaProps: any, type: string, notification = undefined) => {
        const {setButtonLoader, table, setTable} = get();
        try {
            setButtonLoader(true);
            const newAddedField = newColumnAdded(schemaProps, table.schema.properties);
            const payload = {...table.schema, properties: schemaProps};
            const response = await updateTable(payload, table._id);
            setTable({...table, schema: payload});
            const toast = () => {
                switch (type) {
                    case 'delete-column':
                        notification &&
                            notification(
                                'success',
                                t(
                                    'dynamic-components:apiResponseNotification.notifyDeleteFieldSuccess'
                                )
                            );
                        break;
                    case 'add-column':
                        notification &&
                            notification(
                                'success',
                                t(
                                    'dynamic-components:apiResponseNotification.notifyCreateFieldSuccess'
                                )
                            );
                        break;
                }
            };
            toast();

            if (newAddedField.length > 0) {
                // Assuming we are adding only one column at the time
                pendoSendTrackEvent(trackTypeColumnAdded(newAddedField[0].component));
            }

            pendoSendTrackEvent(trackUpdateDataTable);
            return response;
        } catch (err) {
            notification && notification('error', `message failed ${err}`);
        } finally {
            setButtonLoader(false);
            // setReloadRecords(false);
        }
    },

    setManageColumnModal: (value: boolean) => {
        set(() => ({isManageColumnModalOpen: value}));
    },
    deleteTable: async (notification = undefined) => {
        const {table} = get();
        try {
            const response = await deleteTable(table._id);
            notification &&
                notification(
                    'success',
                    t('dynamic-components:apiResponseNotification.notifyDeleteTableSuccess')
                );
            pendoSendTrackEvent(trackdeleteDataTable);
            return response;
        } catch {
            notification && notification('error', `Failed to delete Table`);
        }
    },

    setReloadTabs: (bool: boolean) => {
        set(() => ({reloadTabs: bool}));
    },

    updateTable: async (name: string, notification = undefined) => {
        const {table, setTable} = get();
        try {
            const payload = {...table.schema, schemaLabel: name};
            const response = await updateTable(payload, table._id);
            setTable({...table, schema: payload});
            notification &&
                notification(
                    'success',
                    t('dynamic-components:apiResponseNotification.notifyUpdateTableSuccess')
                );
            pendoSendTrackEvent(trackUpdateDataTableName);
            return response;
        } catch (err) {
            notification &&
                notification(
                    'error',
                    t('dynamic-components:apiResponseNotification.notifyUpdateTableSuccess')
                );
        }
    },
    shiftColumn: async (
        fromIndex: number,
        toIndex: number,
        schemaProps: Array<any>,
        notification = undefined
    ) => {
        const {table, setTable} = get();
        try {
            const updatedProperties = [...schemaProps];
            const temp = updatedProperties[fromIndex];
            updatedProperties[fromIndex] = updatedProperties[toIndex];
            updatedProperties[toIndex] = temp;

            const payload = {...table.schema, properties: updatedProperties};
            //  Optimistic update the table state before the api success
            setTable({...table, schema: payload});
            const response = await updateTable(payload, table._id);
            return response;
        } catch (err) {
            // Reverting to older table state on api failure.
            const payload = {...table.schema, properties: schemaProps};
            setTable({...table, schema: payload});
            notification &&
                notification(
                    'error',
                    t('dynamic-components:apiResponseNotification.notifyApiFailure')
                );
        }
    },
});
