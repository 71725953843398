import styled from '@emotion/styled';

export const StyledTagRemoveBtn = styled.button`
    border: none;
    background: transparent;
    cursor: pointer;
    padding-inline: 0;
`;

export const StyledSpanVerticalAlign = styled.span`
    vertical-align: middle;
`;

export const AssigneeMultiUserFieldWrapper = styled.div<{isEditable: boolean}>`
    pointer-events: ${(prop) => (prop.isEditable ? 'auto' : 'none')};
`;
