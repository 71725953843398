import {Select, Tag} from 'antd';
import {memo, useEffect, useRef} from 'react';

import {COLORS, MAX_ASSIGNEE_LIMIT, REMOVE_TAG_BUTTON_CLASS} from '../../../../constants/constants';
import {FieldProperties, TagRender} from '../../../../engine/types';
import {useStore} from '../../../../store/store';
import {Icons} from '../../../../utils/icons/icon';
import {t} from '../../../../utils/translate';
import {
    AssigneeMultiUserFieldWrapper,
    StyledSpanVerticalAlign,
    StyledTagRemoveBtn,
} from './AccountUserMultiSelect.styled';

const {Option} = Select;
export interface AccountUserMultiSelectProps extends FieldProperties {
    value: string[] | [];
    disabled?: boolean;
    validations?: {
        type: [string];
        limit?: {
            value: number;
            message: string;
        };
    };
    attributes?: {
        allowMultiSelect?: boolean;
    };
}

export const AccountUserMultiSelectAntd = memo(
    ({
        value,
        onChange,
        validations,
        label,
        attributes,
        required,
        disabled,
        forwardedRef,
    }: AccountUserMultiSelectProps) => {
        const users = useStore((state) => state.userList);
        const containerRef: any = useRef(null);
        // attributes is object.
        // if attributes is null, by default allowMultiSelect is true
        // otherwise destructuring form attributes object.
        const {allowMultiSelect = true} = attributes || {allowMultiSelect: true};

        useEffect(() => {
            if (value?.length > 0 && containerRef?.current) {
                updateTabIndex();
            }
        }, [value]);

        useEffect(() => {
            const resizeObserver = new ResizeObserver(updateTabIndex);

            if (containerRef.current) {
                resizeObserver.observe(containerRef.current);
            }

            return () => {
                if (containerRef.current) {
                    resizeObserver.unobserve(containerRef.current);
                }
            };
        }, []);

        const userValues = Object.keys(users).map((key) => {
            return {value: users[key]?.Id, label: users[key]?.FullName};
        });

        const tagRender: TagRender = ({label, value, closable, onClose}) => {
            const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
                event.preventDefault();
                event.stopPropagation();
            };
            return (
                <Tag
                    closeIcon={
                        <StyledTagRemoveBtn
                            className={REMOVE_TAG_BUTTON_CLASS}
                            aria-label={t(
                                'dynamic-components:manageField.fields.userMultiSelect.removeBtnAriaLabel',
                                {
                                    userName: users[value]?.FullName,
                                }
                            )}
                            onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
                                if (e.key === 'Enter') {
                                    e.stopPropagation();
                                    onClose();
                                }
                            }}
                            type="button"
                        >
                            <StyledSpanVerticalAlign>
                                <Icons.Close size={16} />
                            </StyledSpanVerticalAlign>
                        </StyledTagRemoveBtn>
                    }
                    bordered={false}
                    style={{
                        marginInlineEnd: 4,
                        background: COLORS['default'].background,
                        color: COLORS['default'].color,
                    }}
                    onMouseDown={onPreventMouseDown}
                    closable={closable}
                    onClose={onClose}
                >
                    {users[value]?.FullName ?? label}
                </Tag>
            );
        };

        const render = () => {
            return userValues.map((option) => (
                <Option key={option.value} value={option.value} label={option.label}>
                    {/* Customize the label rendering here */}
                    <Tag
                        bordered={false}
                        style={{
                            background: COLORS['default'].background,
                            color: COLORS['default'].color,
                        }}
                    >
                        {option.label}
                    </Tag>
                </Option>
            ));
        };

        const updateTabIndex = () => {
            const removeTagButtons = containerRef.current.querySelectorAll(
                `button.${REMOVE_TAG_BUTTON_CLASS}`
            );
            if (removeTagButtons) {
                removeTagButtons.forEach((btn: any) => {
                    if (btn.closest('div[aria-hidden="true"]')) {
                        btn.setAttribute('tabIndex', -1);
                    } else {
                        btn.setAttribute('tabIndex', 0);
                    }
                });
            }
        };

        const onUserChange = (e: string | string[] | undefined) => {
            const data: any = {value: [], errorMessage: ''};
            if (e) {
                if (!allowMultiSelect) {
                    // sending data in array format if it is single selection.
                    onChange({...data, value: [e]});
                } else {
                    onChange({...data, value: e});
                }
            } else {
                onChange(data);
            }
        };

        return (
            <AssigneeMultiUserFieldWrapper isEditable={!disabled}>
                <div className="multi-select" ref={containerRef}>
                    <Select
                        mode={allowMultiSelect ? 'multiple' : undefined}
                        maxCount={Math.min(
                            validations?.limit?.value ?? MAX_ASSIGNEE_LIMIT,
                            MAX_ASSIGNEE_LIMIT
                        )}
                        tagRender={tagRender}
                        onChange={onUserChange}
                        placeholder={t('dynamic-components:formLabels.placeholderTextForm')}
                        optionFilterProp="label"
                        style={{width: '100%'}}
                        value={value}
                        autoFocus
                        maxTagCount="responsive"
                        allowClear
                        ref={forwardedRef}
                        aria-label={`${label} ${allowMultiSelect ? 'multi-select' : 'select'}`}
                        aria-required={required}
                        onBlur={updateTabIndex}
                        disabled={disabled}
                    >
                        {render()}
                    </Select>
                </div>
            </AssigneeMultiUserFieldWrapper>
        );
    }
);
