import styled from '@emotion/styled';
import {Typography} from 'antd';

const {Text} = Typography;

export const HeaderText = styled(Text)`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    font-style: normal;
    margin-right: 8px;
`;

export const SortableHeaderColumn = styled.div<{isRowReverse: boolean}>`
    display: flex;
    justify-content: space-between;
    ${(props) => props.isRowReverse && `flex-direction: row-reverse`};
`;

export const SortableImage = styled.div`
    line-height: 0;
    margin-left: 4px;
    cursor: pointer;
    position: relative;
`;

export const HeaderDiv = styled.div`
    text-overflow: ellipsis;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
`;
export const HeaderStyles = styled.div`
    .ant-row {
        flex-flow: nowrap;
    }
`;
export const ResizableHandle = styled.span`
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: ew-resize;
    display: grid;
    place-content: center;
`;
