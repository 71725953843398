import {Button, Flex} from 'antd';
import {MouseEvent, useEffect, useRef, useState} from 'react';

import {Loader} from '../../common/loader/Loader';
import {RIGHT_ALIGNED_FIELDS} from '../../constants/constants';
import {t} from '../../utils';
import {useTableContext} from './context/TableContext';
import {ColumnDefinition, TableColumnRenderer} from './DataTable';
import {InputField} from './Fields/Edit/InputField';
import {FieldType} from './Fields/Fields';
import FieldMapper from './Fields/Mapper';
import {TableAction} from './header';
import cellNavigation from './util/accessibility/cellNavigation';

interface CustomCellProps extends TableColumnRenderer {
    record: any;
    children: React.ReactNode;
    column: ColumnDefinition;
    editable: boolean;
    colKey: string;
    colIndex: number;
}

const CustomCell = ({
    children,
    colKey,
    editable,
    record,
    column,
    colIndex,
    dataIndex,
    ...restProps
}: CustomCellProps) => {
    const fieldRef = useRef<any>(null);
    const {onColumnAction, operations} = useTableContext();
    const [editingField, setEditingField] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const {_id: id = '', read_only} = record ?? {};
    const uniqueCellKey = `${id}_${dataIndex}`;
    const isInLineAddRecord = id === 'addrecord';
    const isCellEditable = editable && operations?.canModifyRecord;
    const shouldShowOpenBtn = colIndex == 0 && !isInLineAddRecord;

    if (!colKey || !isCellEditable) {
        return (
            <td {...restProps} key={uniqueCellKey}>
                {children}
            </td>
        );
    }

    const InlineComponent = FieldMapper.table?.edit?.[column.component] || InputField;

    useEffect(() => {
        if (!fieldRef?.current) return;
        fieldRef.current.focus();
    }, [fieldRef]);

    const handleOnChange = async (fieldVal: FieldType, isValid = true) => {
        if (!isValid || fieldVal === record[colKey]) {
            setEditingField('');
            return;
        }
        setIsLoading(true);
        await onColumnAction({
            action: isInLineAddRecord ? TableAction.CREATE_INLINE_ROW : TableAction.EDIT_CELL,
            value: {rowId: id, colId: colKey, colValue: fieldVal},
        });

        setEditingField('');
        setIsLoading(false);
    };

    const commonProps = {
        attributes: column?.attributes ?? {},
        fieldValue: record[colKey],
        onChange: handleOnChange,
        forwardedRef: fieldRef,
        required: column?.required === true,
        recordId: record._id,
        onInlineUpdateCompletion: () => setEditingField(''),
        name: dataIndex,
        record,
    };

    const renderCell = isLoading ? <Loader height="30px" /> : <InlineComponent {...commonProps} />;

    const handleOnClickCell = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        toggleEdit();
    };

    const toggleEdit = () => {
        if (!editable) return;
        if (read_only || (isInLineAddRecord && colIndex != 0)) return;
        if (column.component === 'FileStorageField' && !record[colKey]) {
            const payload = (record._id, {[column.name]: column.name}, undefined, true);
            // eslint-disable-next-line no-console
            console.log('Clicked', payload);
            return;
        }
        setEditingField(uniqueCellKey);
    };

    const handleKeyDown = (cellEvent: React.KeyboardEvent<HTMLDivElement>) => {
        cellNavigation(cellEvent, toggleEdit, openButtonClick);
    };

    const getJustifyContent = () => {
        if (isLoading) return 'center';
        return RIGHT_ALIGNED_FIELDS.includes(column.component) ? 'right' : 'left';
    };

    const isEditMode = () => {
        return uniqueCellKey === editingField;
    };

    const openButtonClick = () => {
        onColumnAction({
            action: TableAction.EDIT_ROW,
            value: {rowId: id, rowValue: record},
        });
    };

    const renderOpenBtn = () => {
        return (
            <Button
                aria-label={t('dynamic-components:editCell.openButtonAriaLabel')}
                tabIndex={0}
                onClick={(e) => {
                    e?.stopPropagation();
                    openButtonClick();
                }}
                className="open-btn"
            >
                {t('dynamic-components:editCell.openButton')}
            </Button>
        );
    };

    const renderChildNode = () => (
        <Flex
            className={`table-cell ${read_only && 'disabled-row'} ${
                isEditMode() ? 'edit' : 'view'
            }`}
            onClick={handleOnClickCell}
            onKeyDown={handleKeyDown}
            tabIndex={isCellEditable ? -1 : 0}
            align="center"
        >
            <div
                className={`editable-cell-value-wrap ${isEditMode() ? 'edit' : 'view'}`}
                role="button"
                tabIndex={0}
                aria-label={`Toggle edit for ${column.label || colKey}`}
                style={{
                    justifyContent: getJustifyContent(),
                }}
            >
                {isEditMode() ? renderCell : children}
            </div>
            {shouldShowOpenBtn && renderOpenBtn()}
        </Flex>
    );

    return (
        <td
            {...restProps}
            key={uniqueCellKey}
            tabIndex={-1}
            aria-labelledby={`header-${dataIndex}}`}
        >
            {renderChildNode()}
        </td>
    );
};
export default CustomCell;
