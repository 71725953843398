import {Add} from '@sharefiledev/icons';
import {Button} from 'antd';
import {useState} from 'react';

import {t} from '../../../../utils';
import {useTableContext} from '../../context/TableContext';
import {addColumnBtnStyle} from '../../DataTable.styled';
import {ManageColumnModal} from './ManageColumnModal';

export const AddColumn = () => {
    const {allowedColumnFields} = useTableContext();
    const [isManageColumnModalOpen, setManageColumnModal] = useState(false);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' || e.key === ' ') {
            setManageColumnModal(true);
        }
    };

    return allowedColumnFields.length ? (
        <>
            <div
                onClick={() => setManageColumnModal(true)}
                onKeyDown={handleKeyDown}
                id="add-column-id"
                data-testid="add-column-id"
            >
                <Button
                    aria-label={t('dynamic-components:manageField.addColumnAriaLabel')}
                    type="text"
                    style={addColumnBtnStyle}
                    onClick={() => setManageColumnModal(true)}
                >
                    <Add />
                    <div>{t('dynamic-components:manageField.addColumnLabel')}</div>
                </Button>
            </div>
            {isManageColumnModalOpen && (
                <ManageColumnModal setManageColumnModal={setManageColumnModal} />
            )}
        </>
    ) : (
        <></>
    );
};
