import {enableMapSet} from 'immer';

import {createTableRecord, deleteTableRecord, getRecords, updateRecord} from '../api/dcsApi';
import {FileAction} from '../engine/types';
import {FormState, RecordQueryParam} from '../model/types';
import {
    pendoSendTrackEvent,
    trackCreateDataTableRecord,
    trackdeleteDataTableRecord,
    trackUpdateDataTableRecord,
} from '../tracking';
import {t} from '../utils';
import {getRecordLabel, hasEmptyFileFields} from '../utils/utils';
import {EditorValueType, RecordSlice, SliceStateCreator} from './types';

/**
 * This function returns single row based on schema with empty cells.
 * @param schema
 * @returns
 */
const generateEmptyRow = (schema: any) => {
    let newRecord: any = {_id: 'addrecord'};
    schema.properties.forEach(({name}: any) => {
        newRecord = {...newRecord, [name]: ''};
    });
    return newRecord;
};
enableMapSet();

export const recordSliceInitialState = {
    records: [],
    selectedRecord: {},
    formState: FormState.CLOSE,
    isLoadingRecords: false,
    requiredFilesMap: new Map<string, number>(),
    recordQueryParams: {},
    textEditorState: [
        {
            fieldName: null,
            fieldValue: null,
        },
    ],
    reload: false,
    disableOnPopoverChange: false,
    fileCount: 0,
};

export const createRecordSlice: SliceStateCreator<RecordSlice> = (set: any, get: any) => ({
    ...recordSliceInitialState,

    setRequiredFilesMap: (key: string, value: number) => {
        set((prevState: any) => {
            const updatedFilesMap = new Map(prevState.requiredFilesMap);
            updatedFilesMap.set(key, value);
            return {requiredFilesMap: updatedFilesMap};
        });
    },

    setFormState: (newState: string) => {
        set(() => ({formState: newState}));
    },
    onAddRecord: (schema: any) => {
        const {records} = get();
        set({
            records: [...records, ...[generateEmptyRow(schema)]],
        });
    },

    onLoadRecords: () => {
        set({records: []});
    },

    setSelectedRecord: (selectedRecord: any = null) => {
        if (!selectedRecord) {
            set({selectedRecord: {}});
        } else {
            set((prevState: any) => ({
                selectedRecord: {...(prevState.selectedRecord ?? {}), ...selectedRecord},
            }));
        }
    },
    setRecordQueryParams: (tableId: string, key = '', param = '') => {
        if (tableId && key && param) {
            set((prevState: RecordSlice) => ({
                recordQueryParams: {
                    ...prevState.recordQueryParams,
                    [tableId]: key
                        ? {...prevState.recordQueryParams[tableId], [key]: param}
                        : param,
                },
            }));
        } else {
            set((prevState: RecordSlice) => ({
                recordQueryParams: {...prevState.recordQueryParams, [tableId]: {}},
            }));
        }
    },

    loadRecords: async (
        tableId: string,
        queryParams: RecordQueryParam,
        notification = undefined
    ) => {
        try {
            const {table} = get();
            set({isLoadingRecords: true});
            const records = await getRecords(tableId, queryParams);
            if (records && records.results && records.results.length > 0) {
                set({records: records.results});
            } else if (table?.schema) {
                const emptyRecord: any = [generateEmptyRow(table.schema)];
                set({records: [...emptyRecord]});
                return emptyRecord;
            }
            return records;
        } catch (err) {
            notification &&
                notification(
                    'error',
                    t('dynamic-components:apiResponseNotification.notifyApiFailure')
                );
        } finally {
            set({isLoadingRecords: false});
        }
    },

    updateAssignees: async (
        recordId: string,
        name,
        userIds,
        action: 'add_user' | 'remove_user',
        notification = undefined,
        isInlineEdit = false
    ) => {
        const {records, setButtonLoader, table} = get();
        const currentRecord = records.find((record: any) => record._id === recordId);
        // keeping in the store add/remove assignees, while creating a record through form.
        if (!currentRecord) {
            set((state: any) => {
                const updatedUsers = state.selectedRecord[name] || [];
                if (action === 'add_user') {
                    return {
                        selectedRecord: {
                            ...state.selectedRecord,
                            [name]: [...updatedUsers, ...userIds],
                        },
                    };
                } else {
                    const ids = state.selectedRecord[name].filter(
                        (user: string) => !userIds.includes(user)
                    );
                    return {selectedRecord: {...state.selectedRecord, [name]: [...ids]}};
                }
            });
        } else {
            try {
                let data: any = [];
                if (action === 'add_user') {
                    // checking the duplicate users.
                    if (currentRecord[name] && currentRecord[name].length > 0) {
                        const uniqueUsers = userIds.filter(
                            (id: any) => !currentRecord[name].includes(id)
                        );
                        if (uniqueUsers.length === 0) {
                            return;
                        }
                        data = [...currentRecord[name], ...uniqueUsers];
                    } else {
                        data = [...userIds];
                    }
                } else {
                    data = currentRecord[name].filter((user: string) => !userIds.includes(user));
                }
                const success = await updateRecord(recordId, {[name]: data});
                if (success) {
                    set((state: any) => {
                        const updatedRecord = {...currentRecord, ...success};
                        const updatedRecords = state.records.map((record: any) =>
                            record._id === recordId ? updatedRecord : record
                        );
                        return {records: updatedRecords};
                    });
                    if (!currentRecord) {
                        set((prevState: any) => ({
                            selectedRecord: prevState.selectedRecord
                                ? {...prevState.selectedRecord, [name]: success[name]}
                                : {},
                        }));
                    }
                    pendoSendTrackEvent(trackUpdateDataTableRecord);
                } else {
                    throw new Error('Failed to update record');
                }
                //skip toast message for inline cell update
                !isInlineEdit &&
                    notification &&
                    notification(
                        'success',
                        t('dynamic-components:apiResponseNotification.notifyUpdateRecordSuccess', {
                            tabName: getRecordLabel(table),
                        })
                    );
            } catch (error) {
                notification &&
                    notification(
                        'error',
                        t('dynamic-components:apiResponseNotification.notifyApiFailure')
                    );
            } finally {
                setButtonLoader(false);
            }
        }
    },

    updateRecord: async (
        recordId: string,
        data: any,
        notification = undefined,
        isInlineEdit = false
    ) => {
        const {records, setButtonLoader, table} = get();
        try {
            const currentRecord = records.find((record: any) => record._id === recordId);
            if (!currentRecord) {
                return;
            }
            const hasChanges = Object.keys(data).some((fieldName) => {
                return currentRecord[fieldName] !== data[fieldName];
            });

            const hasEmptyFile = hasEmptyFileFields(table.schema, currentRecord);
            if (!hasChanges && !hasEmptyFile) {
                return;
            }

            if (recordId && !isInlineEdit) setButtonLoader(true);
            const success = await updateRecord(recordId, data);
            if (success) {
                set((state: any) => {
                    const updatedRecord = {...currentRecord, ...success};
                    const updatedRecords = state.records.map((record: any) =>
                        record._id === recordId ? updatedRecord : record
                    );
                    return {records: updatedRecords};
                });
                pendoSendTrackEvent(trackUpdateDataTableRecord);
            } else {
                throw new Error('Failed to update record');
            }
            //skip toast message for inline cell update
            if (!isInlineEdit) {
                notification &&
                    notification(
                        'success',
                        t('dynamic-components:apiResponseNotification.notifyUpdateRecordSuccess', {
                            tabName: getRecordLabel(table),
                        })
                    );
            }
            return success;
        } catch (error) {
            notification &&
                notification(
                    'error',
                    t('dynamic-components:apiResponseNotification.notifyApiFailure')
                );
        } finally {
            setButtonLoader(false);
        }
    },
    createRecord: async (record_payload: any, notification = undefined) => {
        const recordRID = record_payload['_id'];
        delete record_payload._id;
        const {setButtonLoader, table, records, formState} = get();
        try {
            setButtonLoader(true);
            const response = await createTableRecord(record_payload, table._id, recordRID);

            if (response) {
                const updatedRecords = records.filter((record: any) => record._id !== 'addrecord');
                set({
                    records: [...updatedRecords, ...[response]],
                });
                pendoSendTrackEvent(trackCreateDataTableRecord, {
                    schemaLabel: table.schema.schemaLabel,
                });
            }

            !(formState == 'close') &&
                notification &&
                notification(
                    'success',
                    t('dynamic-components:apiResponseNotification.notifyCreateRecordSuccess', {
                        tabName: getRecordLabel(table),
                    })
                );
        } catch (err) {
            notification &&
                notification(
                    'error',
                    t('dynamic-components:apiResponseNotification.notifyApiFailure')
                );
        } finally {
            setButtonLoader(false);
        }
    },
    deleteRecord: async (record_id: string, notification = undefined) => {
        const {setButtonLoader, table} = get();
        try {
            setButtonLoader(true);
            const response = await deleteTableRecord(record_id);
            if (response) {
                set((state: any) => {
                    const updatedRecords = state.records.filter(
                        (item: any) => item._id != record_id
                    );
                    if (updatedRecords.length === 0) {
                        return {records: [generateEmptyRow(table?.schema)]};
                    }
                    return {records: updatedRecords};
                });
                notification &&
                    notification(
                        'success',
                        t('dynamic-components:apiResponseNotification.notifyDeleteRecordSuccess', {
                            tabName: getRecordLabel(table),
                        })
                    );
                pendoSendTrackEvent(trackdeleteDataTableRecord);
                return response;
            }
            return response;
        } catch (error) {
            notification &&
                notification(
                    'error',
                    t('dynamic-components:apiResponseNotification.notifyApiFailure')
                );
        } finally {
            setButtonLoader(false);
        }
    },
    setTextEditorVal: (editorState: EditorValueType) => {
        const {rtFieldName, rtFieldValue} = editorState;
        const {textEditorState} = get();
        const tempTextEditorState = textEditorState;
        const existingIndex = textEditorState.findIndex(
            (item: EditorValueType) => item.rtFieldName === rtFieldName
        );
        if (existingIndex !== -1) {
            // Update existing object
            tempTextEditorState[existingIndex].fieldValue = rtFieldValue;
        } else {
            // Add a new object
            tempTextEditorState.push({
                rtFieldName,
                rtFieldValue,
            });
        }
        set({
            textEditorState: tempTextEditorState,
        });
    },

    setReload: (value: boolean) => set({reload: value}),

    setDisableOnPopoverChange: (value: boolean) => set({disableOnPopoverChange: value}),

    setFileCount: (action: FileAction) => {
        set((state: any) => {
            if (action === 'upload') {
                return {fileCount: state.fileCount + 1}; // Increment on upload
            } else if (action === 'delete') {
                return {fileCount: Math.max(state.fileCount - 1, 0)}; // Decrement on delete, ensuring non-negative count
            } else if (action === 'reset') {
                return {fileCount: 0}; // reset the counter
            }
            return state; // No change for invalid actions
        });
    },
});
