import {Contact, ODataFeed} from '@citrite/sf-api';

import {getAccounts} from '../api/sfApi';
import {logger} from '../utils/logger';
import {ConfigSlice, SliceStateCreator} from './types';

export const createConfigSlice: SliceStateCreator<ConfigSlice> = (set: any) => ({
    pageLoader: false,
    buttonLoader: false,
    setPageLoader: (value: boolean) => {
        set(() => ({pageLoader: value}));
    },
    setButtonLoader: (value: boolean) => {
        set(() => ({buttonLoader: value}));
    },
    getAssigneeAccounts: async (input, type, selectedIds): Promise<Contact[]> => {
        const searchFilter = (person: Contact, selectedIds: Contact[]) => {
            return !selectedIds.some((contact: Contact) => contact.Id === person.Id);
        };
        const getContacts: (input: string) => Promise<Contact[]> = async (input: string) => {
            try {
                return getAccounts(type, input).then((result: ODataFeed<Contact>) => {
                    return (
                        result.value?.filter((val: Contact) => searchFilter(val, selectedIds)) || []
                    );
                });
            } catch (error) {
                logger.logError(`Error getting contacts: ${error}`);
            }
            return Promise.resolve([]);
        };
        return getContacts(input);
    },
    layout: '',
    setLayout: (value: string) => {
        set({layout: value});
    },

    // LEGACY CODE

    // activeRecordId: '',
    // isModalOpen: false,
    // setModalOpen: (value: boolean) => {
    //     set(() => ({isModalOpen: value, layout: value ? Layout.Form : Layout.Table}));
    // },
    // setActiveRecordId: (record_id: string) => {
    //     set({activeRecordId: record_id});
    //     // if (record_id) {
    //     //     const seletedRecord = records.find((item: any) => item._id === record_id);
    //     //     if (seletedRecord) {
    //     //         const formattedSlectedItem: any = {};
    //     //         Object.keys(seletedRecord).forEach((key) => {
    //     //             formattedSlectedItem[key] = {value: seletedRecord[key], errorMessage: ''};
    //     //         });
    //     //         set({layout: Layout.Form});
    //     //     }
    //     // }
    // },
    // Adding support for only one field editable now
    // currentlyEditing: null, // null when no fields are editing. Otherwise, 'recordId_propName'
    // setEditingField: (recordId: string, propName: string, initialValue: any) => {
    //     set({
    //         currentlyEditing: `${recordId}_${propName}`,
    //         editingValue: initialValue,
    //     });
    // },
    // clearEditingField: () => {
    //     set({currentlyEditing: null});
    // },
    // isValidationError,
    // editingValue: {value: '', errorMessage: ''}, // Temp value for the field being edited
    // setEditingValue: (value: InputObj) => {
    //     set({editingValue: value});
    // },
    // cancelEditing: () => {
    //     set({currentlyEditing: null, editingValue: ''});
    // },
    // updateRecordFromEditingValue: () => {
    //     const {currentlyEditing, editingValue, updateRecord} = get();
    //     if (currentlyEditing) {
    //         const [recordId, propName] = currentlyEditing.split('_');
    //         updateRecord(recordId, {[propName]: editingValue['value']}, currentlyEditing);
    //         set({editingValue: {value: '', errorMessage: ''}});
    //     }
    // },
});
