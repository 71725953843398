import {EditorValueType} from '../../../../store/types';

const createNode = (value: string) => ({
    root: {
        children: [
            {
                children: [
                    {
                        detail: 0,
                        format: 0,
                        mode: 'normal',
                        style: '',
                        text: value,
                        type: 'text',
                        version: 1,
                    },
                ],
                direction: 'ltr',
                format: '',
                indent: 0,
                type: 'paragraph',
                version: 1,
                textFormat: 0,
                textStyle: '',
            },
        ],
        direction: 'ltr',
        format: '',
        indent: 0,
        type: 'root',
        version: 1,
    },
});

export const checkIfNode = (value: any) => {
    if (!value) return createNode('');
    return typeof value !== 'object' ? createNode(value) : value;
};

const extractText = (data: any): string => {
    if (typeof data !== 'object') {
        return '';
    }

    let textString = '';
    if (Object.prototype.hasOwnProperty.call(data, 'text')) {
        textString += ` ${data.text}`;
    }

    if (data.children) {
        for (const child of data.children) {
            textString += extractText(child);
        }
    }
    return textString;
};

export const transformNodeToText = (value: any = {}) => {
    if (!value) return '';
    if (typeof value !== 'object' || !Object.prototype.hasOwnProperty.call(value, 'root')) {
        return value;
    }
    const rootChildren = value.root.children;

    if (!rootChildren || !rootChildren.length) {
        return '';
    }

    let textString = '';
    for (const child of rootChildren) {
        textString += extractText(child);
    }

    return textString;
};

export const fetchCurrentField = (arr: EditorValueType[], name: string) =>
    (arr || []).find((item: EditorValueType) => item.rtFieldName === name);

export const createUniqueId = (name: string, id: string) => `${name ?? ''}+${id ?? ''}`.trim();
