import {Button, Dropdown, Flex, MenuProps, Typography} from 'antd';
import {useMemo, useRef} from 'react';

import {ToolbarWrapper} from '../../../components/toolbar/toolbar.styled';
import {FILTER_OPTIONS} from '../../../constants/constants';
import {t} from '../../../utils';
import {Icons} from '../../../utils/icons/icon';
import {useTableContext} from '../context/TableContext';
import {ToolbarProps} from '../DataTable';
import {TableAction} from '../header';
import {Filter} from './filters';

const {Text} = Typography;

export const Toolbar = ({
    columns,
    createButtonLabel,
    items,
    createRowButtonLoading,
    createRowButtonDisabled,
}: ToolbarProps) => {
    const {operations, onColumnAction} = useTableContext();
    const canCreateRecord = operations?.canCreateRecord ?? false;
    const moreOptionRef = useRef<HTMLButtonElement>(null);

    const getFilterOptions = useMemo(
        () => columns.filter((item: any) => FILTER_OPTIONS.includes(item.component)),
        []
    );
    const focusMoreButton = () => moreOptionRef.current?.focus();

    const onClick: MenuProps['onClick'] = (menuOption) => {
        onColumnAction({
            action: menuOption.key as TableAction,
            value: '',
            handleFocus: focusMoreButton,
        });
    };

    return (
        <ToolbarWrapper>
            <Flex wrap="wrap" gap="small">
                {<Filter items={getFilterOptions} />}
                {items?.length && (
                    <Dropdown
                        autoFocus
                        menu={{items, onClick}}
                        placement="bottomLeft"
                        arrow
                        trigger={['click']}
                    >
                        <Button id="dropdownMenu-table-btn" type="text" ref={moreOptionRef}>
                            <Icons.MenuDotVertical size={18} />
                            <Text>{t('dynamic-components:moreOptions.label')}</Text>
                        </Button>
                    </Dropdown>
                )}
            </Flex>
            {canCreateRecord && (
                <Flex wrap="wrap" gap="small" justify="flex-end">
                    <Button
                        onClick={() => onColumnAction({action: TableAction.ADD_ROW, value: ''})}
                        data-testid="create-row"
                        type="primary"
                        disabled={createRowButtonDisabled}
                        loading={createRowButtonLoading}
                    >
                        {createButtonLabel}
                    </Button>
                </Flex>
            )}
        </ToolbarWrapper>
    );
};
