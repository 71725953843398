import {create} from 'zustand';
import {devtools} from 'zustand/middleware';
import {immer} from 'zustand/middleware/immer';

import {createConfigSlice} from './configSlice';
import {createRecordSlice} from './recordSlice';
import {createResetSlice} from './resetSlice';
import {createSchemaSlice} from './schemaSlice';
import {IStore} from './types';
import {createUsersSlice} from './userSlice';

export const useStore = create<IStore, [['zustand/immer', never], ['zustand/devtools', never]]>(
    immer(
        devtools((...a) => ({
            ...createSchemaSlice(...a),
            ...createConfigSlice(...a),
            ...createRecordSlice(...a),
            ...createUsersSlice(...a),
            ...createResetSlice(...a),
        }))
    )
);
