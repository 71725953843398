import {useState} from 'react';

import {t} from '../../../utils';
import {FieldType} from '../Fields/Fields';

export const useFieldOperations = (
    fieldValue: FieldType,
    required: boolean,
    validations: any,
    onChange: (value: FieldType, isValid: boolean) => void
) => {
    const [inputValue, setInputValue] = useState(() => fieldValue);
    const [isError, setIsError] = useState(false);

    const validate = (inputValue: FieldType) => {
        if (required && !inputValue) {
            return t('dynamic-components:validations.required');
        }
        if (typeof inputValue === 'number') return '';
        if (validations?.minLength && inputValue.length < validations?.minLength.value) {
            return validations?.minLength.message;
        } else if (validations?.maxLength && inputValue.length > validations?.maxLength.value) {
            return validations?.maxLength.message;
        } else {
            return '';
        }
    };

    const handleOnBlur = () => {
        onChange(inputValue, !isError);
    };

    const handleOnChange = (value: FieldType) => {
        setInputValue(value);
        const errorMessage = required && validate(value);
        if (errorMessage) {
            setIsError(true);
        }
    };
    return {inputValue, handleOnChange, isError, handleOnBlur} as const;
};
