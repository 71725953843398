import {DatePicker} from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import {memo, useMemo} from 'react';

import {DEFAULT_DATE_FORMAT} from '../../constants/constants';

const {RangePicker} = DatePicker;

type DateRangePickerProps = {
    value: [number, number] | [];
    onChange: (e: {value: [number, number] | []; errorMessage: string}) => void;
    ariaLabel?: string;
    dateFormat?: string;
    forwardedRef: React.RefObject<any>;
};

export const DateRangePicker = memo(
    ({onChange, value, ariaLabel, dateFormat, forwardedRef}: DateRangePickerProps) => {
        const values: [start: Dayjs | null | undefined, end: Dayjs | null | undefined] =
            useMemo(() => {
                if (value && value.length === 2) {
                    return [dayjs(value[0] * 1000), dayjs(value[1] * 1000)];
                } else {
                    return [null, null];
                }
            }, [value]);

        const onDateChange = (_dates: any, dateStrings: [string, string]) => {
            const [startDateString, endDateString] = dateStrings;
            if (startDateString && endDateString) {
                onChange({
                    value: [dayjs(startDateString).unix(), dayjs(endDateString).unix()],
                    errorMessage: '',
                });
            } else {
                onChange({value: [], errorMessage: ''});
            }
        };

        return (
            <RangePicker
                id="date-range-picker"
                value={values}
                onChange={onDateChange}
                style={{width: '100%'}}
                ref={forwardedRef}
                aria-label={`${ariaLabel} date-picker`}
                format={dateFormat ?? DEFAULT_DATE_FORMAT}
            />
        );
    }
);
