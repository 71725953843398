import {AccountsEntity, Contact as SfContact, ContactListType, UsersEntity} from '@citrite/sf-api';
import {RID} from '@sharefiledev/sfplugin-resource-id';

export const useContactSearch = () => {
    const searchContacts = async (
        value: string,
        contactListType: string = ContactListType.AllUsers,
        matchName = true,
        matchCompany = true
    ) => {
        let filter = `substringof('${value}', Email) eq true`;
        if (matchName) {
            const escapedSearchTerm = sanitizeSearchTerm(value);
            const regex = escapedSearchTerm.replace(/\s+/g, '|');
            const searchTokens = getSearchTokensFromRegex(regex, '|');
            // First token with 'or' operator
            if (searchTokens.length > 0) {
                filter += ` or substringof('${searchTokens[0]}', Name) eq true`;
            }

            // Remaining tokens with 'and' operator
            for (let i = 1; i < searchTokens.length; i++) {
                filter += ` and substringof('${searchTokens[i]}', Name) eq true`;
            }
        }
        if (matchCompany) {
            filter += ` or substringof('${value}', Company) eq true`;
        }

        const result = await AccountsEntity.getAddressBook(contactListType)
            .filter(filter)
            .execute();
        return result.value?.map(convertToInternalContact);
    };

    const getUser = async (emailAddress: string) => {
        return await UsersEntity.get(undefined, emailAddress).execute();
    };

    const getUserByRID = async (userRID: string) => {
        const parsedUserRID = RID.parse(userRID);
        return await UsersEntity.get(parsedUserRID.entityId).execute();
    };
    return {searchContacts, getUser, getUserByRID};
};

const sanitizeSearchTerm = (searchString: string) => {
    if (!searchString) return '';
    return searchString
        .toLowerCase()
        .trim()
        .replace(/([()[{*+.$^\\|?])/g, '\\$1');
};

const getSearchTokensFromRegex = (searchString: string, splitChar: string) => {
    if (searchString) {
        return searchString.replace(/\\/g, '').split(splitChar);
    }
    return [];
};

export const convertToInternalContact = (contact: SfContact) => {
    return {
        id: contact.Id ?? '',
        firstName: contact.FirstName ?? '',
        lastName: contact.LastName ?? '',
        email: contact.Email ?? '',
    };
};
