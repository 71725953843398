import {Input} from 'antd';

import {useFieldOperations} from '../../hooks/useFieldOperations';
import {InputFieldProps} from '../Fields';

export const InputField = ({
    forwardedRef,
    fieldValue,
    onChange,
    required,
    validations,
}: InputFieldProps) => {
    const {inputValue, handleOnChange, isError, handleOnBlur} = useFieldOperations(
        fieldValue,
        required,
        validations,
        onChange
    );

    return (
        <Input
            value={inputValue}
            onChange={(event) => handleOnChange(event.target.value)}
            onBlur={handleOnBlur}
            ref={forwardedRef}
            autoFocus
            status={isError ? 'error' : ''}
        />
    );
};
