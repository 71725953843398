import {ChevronDown} from '@sharefiledev/icons';
import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {createPortal} from 'react-dom';

import {useRichText} from '../../../../../layouts/table/context/RichText';
import {Dropdown, DropdownItem} from '../SFLexicalRichTextEditor.styled';
import {renderFontIcon} from './helper';

type DropDownContextType = {
    registerItem: (ref: React.RefObject<HTMLButtonElement>) => void;
};

const DropDownContext = createContext<DropDownContextType | null>(null);

const dropDownPadding = 4;

export const FontDropDownItem = ({
    children,
    onClick,
    title,
}: {
    children: React.ReactNode;
    className: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    title?: string;
}) => {
    const ref = useRef<HTMLButtonElement>(null);

    const dropDownContext = useContext(DropDownContext);

    if (dropDownContext === null) {
        throw new Error('DropDownItem must be used within a DropDown');
    }

    const {registerItem} = dropDownContext;

    useEffect(() => {
        if (ref && ref.current) {
            registerItem(ref);
        }
    }, [ref, registerItem]);

    return (
        <DropdownItem onClick={onClick} ref={ref} title={title} type="button" isTextAlign>
            {children}
        </DropdownItem>
    );
};

const DropDownItems = ({
    children,
    dropDownRef,
    onClose,
}: {
    children: React.ReactNode;
    dropDownRef: React.Ref<HTMLDivElement>;
    onClose: () => void;
}) => {
    const [items, setItems] = useState<React.RefObject<HTMLButtonElement>[]>();
    const [highlightedItem, setHighlightedItem] = useState<React.RefObject<HTMLButtonElement>>();

    const registerItem = useCallback(
        (itemRef: React.RefObject<HTMLButtonElement>) => {
            setItems((prev) => (prev ? [...prev, itemRef] : [itemRef]));
        },
        [setItems]
    );

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (!items) {
            return;
        }

        const key = event.key;

        if (['Escape', 'ArrowUp', 'ArrowDown', 'Tab'].includes(key)) {
            event.preventDefault();
        }

        if (key === 'Escape' || key === 'Tab') {
            onClose();
        } else if (key === 'ArrowUp') {
            setHighlightedItem((prev) => {
                if (!prev) {
                    return items[0];
                }
                const index = items.indexOf(prev) - 1;
                return items[index === -1 ? items.length - 1 : index];
            });
        } else if (key === 'ArrowDown') {
            setHighlightedItem((prev) => {
                if (!prev) {
                    return items[0];
                }
                return items[items.indexOf(prev) + 1];
            });
        }
    };

    const contextValue = useMemo(
        () => ({
            registerItem,
        }),
        [registerItem]
    );

    useEffect(() => {
        if (items && !highlightedItem) {
            setHighlightedItem(items[0]);
        }

        if (highlightedItem && highlightedItem.current) {
            highlightedItem.current.focus();
        }
    }, [items, highlightedItem]);

    return (
        <DropDownContext.Provider value={contextValue}>
            <Dropdown ref={dropDownRef} onKeyDown={handleKeyDown}>
                {children}
            </Dropdown>
        </DropDownContext.Provider>
    );
};

export default function FontDropDown({
    disabled = false,
    buttonLabel,
    buttonAriaLabel,
    buttonClassName,
    buttonIconName,
    children,
    stopCloseOnClickSelf,
}: {
    disabled?: boolean;
    buttonAriaLabel?: string;
    buttonClassName: string;
    buttonIconName: string;
    buttonLabel?: string;
    children: ReactNode;
    stopCloseOnClickSelf?: boolean;
}): JSX.Element {
    const dropDownRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [showDropDown, setShowDropDown] = useState(false);
    const {setDisableOnPopoverChange} = useRichText();

    const handleClose = () => {
        setShowDropDown(false);
        setDisableOnPopoverChange(false);
        if (buttonRef && buttonRef.current) {
            buttonRef.current.focus();
        }
    };

    useEffect(() => {
        const button = buttonRef.current;
        const dropDown = dropDownRef.current;

        if (showDropDown && button !== null && dropDown !== null) {
            const {top, left} = button.getBoundingClientRect();
            dropDown.style.top = `${top + button.offsetHeight + dropDownPadding}px`;
            dropDown.style.left = `${Math.min(
                left,
                window.innerWidth - dropDown.offsetWidth - 20
            )}px`;
        }
    }, [dropDownRef, buttonRef, showDropDown]);

    useEffect(() => {
        const button = buttonRef.current;

        if (button !== null && showDropDown) {
            const handle = (event: MouseEvent) => {
                const target = event.target;
                if (stopCloseOnClickSelf) {
                    if (dropDownRef.current && dropDownRef.current.contains(target as Node)) {
                        return;
                    }
                }
                if (!button.contains(target as Node)) {
                    setShowDropDown(false);
                    setDisableOnPopoverChange(false);
                }
            };
            document.addEventListener('click', handle);

            return () => {
                document.removeEventListener('click', handle);
            };
        }
        return;
    }, [dropDownRef, buttonRef, showDropDown, stopCloseOnClickSelf]);

    useEffect(() => {
        const handleButtonPositionUpdate = () => {
            if (showDropDown) {
                const button = buttonRef.current;
                const dropDown = dropDownRef.current;
                if (button !== null && dropDown !== null) {
                    const {top} = button.getBoundingClientRect();
                    const newPosition = top + button.offsetHeight + dropDownPadding;
                    if (newPosition !== dropDown.getBoundingClientRect().top) {
                        dropDown.style.top = `${newPosition}px`;
                    }
                }
            }
        };

        document.addEventListener('scroll', handleButtonPositionUpdate);

        return () => {
            document.removeEventListener('scroll', handleButtonPositionUpdate);
        };
    }, [buttonRef, dropDownRef, showDropDown]);

    return (
        <>
            <button
                type="button"
                disabled={disabled}
                aria-label={buttonAriaLabel || buttonLabel}
                className={buttonClassName}
                onClick={() => {
                    setShowDropDown(!showDropDown);
                    setDisableOnPopoverChange(true);
                }}
                ref={buttonRef}
            >
                <span className="icon block-type ">{renderFontIcon[buttonIconName]}</span>
                {buttonLabel && <span className="text-alignment">{buttonLabel}</span>}
                <ChevronDown size={20} />
            </button>

            {showDropDown &&
                createPortal(
                    <DropDownItems dropDownRef={dropDownRef} onClose={handleClose}>
                        {children}
                    </DropDownItems>,
                    document.body
                )}
        </>
    );
}
