import {Typography} from 'antd';

// import {FieldProperties} from '../../../../engine/types';
import {InputFieldProps} from '../Fields';

const {Text} = Typography;

const truncateString = (val: string, maxLength: number) =>
    val.length > maxLength ? val.substring(0, maxLength) + '...' : val;

export const TextField = ({fieldValue}: InputFieldProps) => {
    if (!fieldValue) {
        return null;
    }
    if (typeof fieldValue == 'number') {
        const formattedNumber = fieldValue;
        return <Text ellipsis={{tooltip: `${fieldValue}`}}>{formattedNumber}</Text>;
    }
    const renderedText = truncateString(fieldValue, 100);
    return <Text ellipsis={{tooltip: `${renderedText}`}}>{renderedText}</Text>;
};
