import {Typography} from 'antd';

import {currencySymbol, DEFAULT_DECIMAL_VALUE} from '../../../../constants/constants';
import {formatCurrencyInput} from '../../../../utils/utils';
import {CurrencyFieldProps} from '../Fields';

const {Text} = Typography;
export const CurrencyField = ({attributes, fieldValue = '0', validations}: CurrencyFieldProps) => {
    const formattedNumber = Number(fieldValue).toLocaleString();
    const currency = attributes?.currency as keyof typeof currencySymbol;
    const decimalValue = validations?.decimal_places?.value ?? DEFAULT_DECIMAL_VALUE;
    const formattedCurrencyInput = formatCurrencyInput(formattedNumber, decimalValue);
    const currencyValue = `${currencySymbol[currency]} ${formattedCurrencyInput}`;
    return <Text ellipsis={{tooltip: `${currencyValue}`}}>{currencyValue}</Text>;
};
