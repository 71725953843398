import {CurrencyArray, FieldsArray, ValidationType} from '../../engine/types';
import {t} from '../../utils';
import {Icons} from '../../utils/icons/icon';
import {IconStyle, LabelStyle} from './fields.styled';

export enum CurrencyEnum {
    USD = 'USD',
    EUR = 'EUR',
    GBP = 'GBP',
    AED = 'AED',
    AFN = 'AFN',
    INR = 'INR',
    AUD = 'AUD',
}

// enum for all field names
export enum FieldsEnum {
    TextInputField = 'TextInputField',
    TextAreaField = 'TextAreaField',
    SingleSelectField = 'SingleSelectField',
    DateField = 'DateField',
    DateOverdueField = 'DateOverdueField',
    AccountUserMultiSelect = 'AccountUserMultiSelect',
    ToggleField = 'ToggleField',
    Select = 'Select',
    DatePickerField = 'DatePickerField',
    DropDownField = 'DropDownField',
    LinkField = 'LinkField',
    FileStorageField = 'FileStorageField',
    AssigneeField = 'AssigneeField',
    CurrencyField = 'CurrencyField',
    NumericField = 'NumericField',
    AssigneeSelectField = 'AssigneeSelectField',
    RichTextEditorField = 'RichTextEditorField',
}

// Function to get all allowed column fields based on Fields enum
export const getAllowedColumnFields = (): FieldsArray => {
    return Object.values(FieldsEnum) as FieldsArray;
};

export const getAllowedCurrencies = (): CurrencyArray => {
    return Object.values(CurrencyEnum) as CurrencyArray;
};

// fields  for UI components
export const fieldsArr = [
    {
        value: FieldsEnum.TextInputField,
        label: t('dynamic-components:manageField.fields.textInput.label'),
        Icon: <Icons.TextEditingTyping />,
    },
    {
        value: FieldsEnum.RichTextEditorField,
        label: t('dynamic-components:manageField.fields.textAreaField.label'),
        Icon: <Icons.TextFields />,
    },
    // Commenting below to hide text area in Add column modal SFPMGT-918
    // {
    //     value: 'TextAreaField',
    //     label: t('dynamic-components:manageField.fields.textAreaField.label'),
    //     Icon: Icons.TextFields,
    // },
    {
        value: FieldsEnum.AccountUserMultiSelect,
        label: t('dynamic-components:manageField.fields.userMultiSelect.label'),
        Icon: <Icons.UserSingleNeutral />,
    },
    {
        value: FieldsEnum.DateOverdueField,
        label: t('dynamic-components:manageField.fields.dateOverdue.label'),
        Icon: <Icons.CalendarMark />,
    },
    {
        value: FieldsEnum.Select,
        label: t('dynamic-components:manageField.fields.singleSelect.label'),
        Icon: <Icons.MultiSelect />,
    },
    {
        value: FieldsEnum.FileStorageField,
        label: t('dynamic-components:manageField.fields.fileStorage.label'),
        Icon: <Icons.Paperclip />,
    },
    {
        value: FieldsEnum.NumericField,
        label: t('dynamic-components:manageField.fields.numericField.label'),
        Icon: <Icons.SignHashtag />,
    },
    {
        value: FieldsEnum.CurrencyField,
        label: t('dynamic-components:manageField.fields.currencyField.label'),
        Icon: <Icons.CoinStack />,
    },
    {
        value: FieldsEnum.LinkField,
        label: t('dynamic-components:manageField.fields.linkField.label'),
        Icon: <Icons.LinkChain />,
    },
];

export const fields = fieldsArr.map((field: any) => ({
    label: (
        <LabelStyle id={field.value} tabIndex={0}>
            <IconStyle>{field.Icon}</IconStyle>
            {field.label}
        </LabelStyle>
    ),
    value: field.value,
}));

type SelectAttributeType = {
    enum: string[];
    enumConfig: Record<
        string,
        {
            badgeType: 'default' | 'info' | 'warning';
            displayName: string;
        }
    >;
};

type FieldAttributes =
    | {format: string} // For DateField
    | {currency: CurrencyEnum} // For CurrencyField
    | SelectAttributeType; // For Select fields

type fieldProps = {
    [key in FieldsEnum | string]?: {
        validations?: ValidationType;
        attributes?: FieldAttributes;
    };
};

export const fieldProps: fieldProps = {
    TextInputField: {
        validations: {
            type: 'string',
        },
    },
    RichTextEditorField: {
        validations: {
            type: 'any',
        },
    },
    // TextAreaField: {
    //     validations: {
    //         type: 'string',
    //     },
    // },
    AccountUserMultiSelect: {
        validations: {
            type: ['string'],
        },
    },
    DateField: {
        validations: {
            type: 'date',
        },
        attributes: {
            format: 'MM/DD/YYYY',
        },
    },
    DateOverdueField: {
        validations: {
            type: 'date',
        },
        attributes: {
            format: 'MM/DD/YYYY',
        },
    },
    Select: {
        validations: {
            type: 'string',
        },
        attributes: {
            enum: ['Option 1', 'Option 2', 'Option 3'],
            enumConfig: {
                'Option 1': {
                    badgeType: 'default',
                    displayName: '',
                },
                'Option 2': {
                    badgeType: 'info',
                    displayName: '',
                },
                'Option 3': {
                    badgeType: 'warning',
                    displayName: '',
                },
            },
        },
    },
    FileStorageField: {
        validations: {
            type: 'string',
        },
    },
    CurrencyField: {
        validations: {
            type: 'currency',
            decimal_places: {
                value: 2,
                message: 'Currency field cannot have more than 2 decimal places',
            },
        },

        attributes: {
            currency: CurrencyEnum.USD,
        },
    },
    NumericField: {
        validations: {
            type: 'number',
        },
    },
    LinkField: {
        validations: {
            type: 'string',
        },
    },
};

export const defaultFieldValues = {
    value: {
        label: '',
        name: '',
        component: 'TextInputField',
        validations: {type: 'string'},
        attributes: {},
    },
    isValid: true,
    isEdit: false,
};
