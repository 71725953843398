import {Input} from 'antd';
import {ChangeEvent} from 'react';

import {useFieldOperations} from '../../hooks/useFieldOperations';
import {NumberFIeldProps} from '../Fields';

export const NumberField = ({
    disabled,
    fieldValue,
    forwardedRef,
    required,
    validations,
    onChange,
}: NumberFIeldProps) => {
    const {inputValue, handleOnChange, handleOnBlur} = useFieldOperations(
        fieldValue ?? 0,
        required,
        validations,
        onChange
    );
    const numberInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        handleOnChange(Number(event.target.value ?? null));
    };

    return (
        <Input
            type="number"
            defaultValue={fieldValue ?? undefined}
            onChange={numberInputOnChange}
            style={{flexGrow: 1, textAlignLast: 'right'}}
            disabled={disabled}
            autoFocus
            ref={forwardedRef}
            value={inputValue}
            onBlur={handleOnBlur}
        />
    );
};
