export const PERMISSIONS = {
    DATATABLE_CREATE: 'datatable:create',
    DATATABLE_READ: 'datatable:read',
    DATATABLE_DELETE: 'datatable:delete',
    DATATABLE_MODIFY: 'datatable:modify',
    DATATABLE_SHARE: 'datatable:share',
    DATATABLE_RECORD_CREATE: 'datatable_record:create',
    DATATABLE_RECORD_READ: 'datatable_record:read',
    DATATABLE_RECORD_DELETE: 'datatable_record:delete',
    DATATABLE_RECORD_MODIFY: 'datatable_record:modify',
    DATATABLE_RECORD_SHARE: 'datatable_record:share',
};
