import {Select, Tag} from 'antd';
import {useRef} from 'react';

import {COLORS, MAX_ASSIGNEE_LIMIT, REMOVE_TAG_BUTTON_CLASS} from '../../../../constants/constants';
import {TagRender} from '../../../../engine/types';
import {t} from '../../../../utils';
import {Icons} from '../../../../utils/icons/icon';
import {useTableContext} from '../../context/TableContext';
import {useAvatarMultiSelectOperation} from '../../hooks/useAvatarMultiSelectOperation';
import {useFieldOperations} from '../../hooks/useFieldOperations';
import {stringToArray} from '../../util';
import {AvatarFieldProps} from '../Fields';
import {StyledSpanVerticalAlign, StyledTagRemoveBtn} from '../Fields.styled';

const {Option} = Select;

export const AvatarUserMultiSelectField = ({
    disabled,
    fieldValue,
    forwardedRef,
    validations,
    attributes,
    required,
    onChange,
}: AvatarFieldProps) => {
    const containerRef: any = useRef(null);
    const avatarValue = stringToArray(fieldValue);
    const {userList = {}} = useTableContext();
    const {allowMultiSelect = true} = attributes || {allowMultiSelect: true};
    const {inputValue, handleOnChange, handleOnBlur} = useFieldOperations(
        avatarValue,
        required,
        validations,
        onChange
    );

    const {operationCompleted} = useAvatarMultiSelectOperation(avatarValue, containerRef);
    const userValues = Object.keys(userList).map((key) => {
        return {value: userList[key]?.Id, label: userList[key]?.FullName};
    });

    const tagRender: TagRender = ({label, value, closable, onClose}) => {
        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                closeIcon={
                    <StyledTagRemoveBtn
                        className={REMOVE_TAG_BUTTON_CLASS}
                        aria-label={t(
                            'dynamic-components:manageField.fields.userMultiSelect.removeBtnAriaLabel',
                            {
                                userName: userList[value]?.FullName,
                            }
                        )}
                        onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
                            if (e.key === 'Enter') {
                                e.stopPropagation();
                                onClose();
                            }
                        }}
                        type="button"
                    >
                        <StyledSpanVerticalAlign>
                            <Icons.Close size={16} />
                        </StyledSpanVerticalAlign>
                    </StyledTagRemoveBtn>
                }
                bordered={false}
                style={{
                    marginInlineEnd: 4,
                    background: COLORS['default'].background,
                    color: COLORS['default'].color,
                }}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
            >
                {userList[value]?.FullName ?? label}
            </Tag>
        );
    };

    const onUserChange = (e: string | string[] | undefined) => {
        if (e) {
            if (!allowMultiSelect) {
                // sending data in array format if it is single selection.
                handleOnChange([e] as string[]);
            } else {
                handleOnChange(e);
            }
        }
    };

    const render = () => {
        return userValues.map((option) => (
            <Option key={option.value} value={option.value} label={option.label}>
                {/* Customize the label rendering here */}
                <Tag
                    bordered={false}
                    style={{
                        background: COLORS['default'].background,
                        color: COLORS['default'].color,
                    }}
                >
                    {option.label}
                </Tag>
            </Option>
        ));
    };

    return (
        <div className="multi-select" ref={containerRef}>
            <Select
                mode={allowMultiSelect ? 'multiple' : undefined}
                maxCount={Math.min(
                    validations?.limit?.value ?? MAX_ASSIGNEE_LIMIT,
                    MAX_ASSIGNEE_LIMIT
                )}
                tagRender={tagRender}
                onChange={onUserChange}
                placeholder={t('dynamic-components:formLabels.placeholderTextForm')}
                optionFilterProp="label"
                value={inputValue as string[] | []}
                autoFocus
                maxTagCount="responsive"
                allowClear
                ref={forwardedRef}
                aria-required={required}
                onBlur={operationCompleted ? handleOnBlur : undefined}
                disabled={disabled}
            >
                {render()}
            </Select>
        </div>
    );
};
