export interface Mode {
    read?: boolean;
    write?: boolean;
}

export interface Permission {
    read?: boolean;
    write?: boolean;
}

export type UserPermission = {
    permissions: string[];
    FullName: string;
    Email: string;
    Id: string;
};

export type UserPermissionResponse = {
    users: Record<string, UserPermission>;
};

export enum SortDirection {
    Asc = 'asc',
    Desc = 'desc',
}

export enum FormState {
    CLOSE = 'close',
    UPDATE = 'update',
    CREATE = 'create',
}

export interface SortParams {
    sortKey?: string;
    direction?: SortDirection;
}

export interface SearchParams {
    searchTerm?: string;
}
export type ColorPalette = {
    value: string;
    background: string;
    color: string;
};

export type Colors = {
    [key in string]: {
        value: string;
        background: string;
        color: string;
        accentColor: string;
    };
};

export type Filters = {
    [key in string]: (string | number)[] | [];
};

export type RecordQueryParam = {
    sort?: string;
    filter?: string;
    search?: string;
    contacts?: string;
    placeholder?: string;
};

export type RecordQueryParams = {
    [key in string]: RecordQueryParam;
};

export const DataTablePiletEvents = {
    deleted: 'urn:event:resource:deleted',
    updated: 'urn:event:resource:updated',
};

export enum DataTableEntityType {
    datatable = 'datatable',
}

export interface ReloadTableResponse {
    tableSchema: any;
    recordsResponse: any;
}
