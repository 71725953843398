import {DownOutlined, ExclamationCircleFilled} from '@ant-design/icons';
import {Dropdown, Modal, Row} from 'antd';
import {useCallback, useMemo, useState} from 'react';
import {memo} from 'react';

import {RIGHT_ALIGNED_FIELDS, SORT_KEYS} from '../../../constants/constants';
import {Filters} from '../../../model/types';
import {SortDownArrow, SortUpArrow} from '../../../svg-icons/SortableIcons';
import {t} from '../../../utils';
import useFocusAndHover from '../../../utils/hooks/useFocusAndHover/useFocusAndHover';
import {useTableContext} from '../context/TableContext';
import {ColumnDefinition} from '../DataTable';
import {ColumnOptions} from './column/ColumnOptions';
import {ManageColumnModal} from './column/ManageColumnModal';
import {HeaderStyles, HeaderText, SortableHeaderColumn, SortableImage} from './TableHeader.styled';

export type Sortobj = {
    sortKey: string;
    direction: SortDirection;
};

export enum SortDirection {
    ASC = 'asc',
    DESC = 'desc',
}

export enum TableAction {
    // Cell actions
    EDIT_CELL = 'edit_cell',

    // Column actions
    EDIT_COLUMN = 'edit-column',
    DELETE_COLUMN = 'delete-column',
    ADD_COLUMN = 'add-column',

    // Row actions
    ADD_ROW = 'add-row',
    EDIT_ROW = 'edit-row',
    CREATE_INLINE_ROW = 'create_inline_row',

    // Table actions
    FILTER = 'filter',
    EDIT_TABLE = 'edit_table',
    SAVE_AS_TEMPLATE = 'save_as_template',
    DELETE_TABLE = 'delete_table',

    // User management
    MANAGE_USERS = 'manage_users',
}

export type CellValueType = {
    rowId: string;
    colId: string;
    colValue: any;
};
export type RowValueType = {
    rowId: string;
    rowValue: any;
};
export type ActionType = {
    action: SortDirection | TableAction;
    value: string | Filters | ColumnDefinition | CellValueType | RowValueType;
    handleFocus?: () => void;
}; // For column actions

export type HeaderProps = {
    column: ColumnDefinition;
    columnIndex: number;
    sort?: Sortobj | undefined;
};

export const Header = memo(({column, columnIndex, sort}: HeaderProps) => {
    const {name, label, component, editable = true, isTemplateField = false} = column;

    const [isManageColumnModalOpen, setManageColumnModal] = useState(false);
    const {onColumnAction, operations, nonSortableColumns} = useTableContext();
    const canDeleteColumn = operations?.canDeleteColumn ?? false; // Default to false if undefined
    const canEditColumn = operations?.canEditColumn ?? false;

    const [modal, contextHolder] = Modal.useModal();
    const {state, handleFocus, handleBlur, handleMouseEnter, handleMouseLeave} = useFocusAndHover();
    const {sortKey, direction} = sort || {};

    const onSortChange = (name: string) => {
        let dir: SortDirection = SortDirection.ASC;
        if (sortKey === name) {
            dir = direction === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC;
        }
        onColumnAction({action: dir, value: name});
    };

    const sortIcon = useMemo(() => {
        if (sortKey === name && direction === SortDirection.DESC) {
            return <SortUpArrow />;
        }
        return <SortDownArrow />;
    }, [sortKey, direction]);

    const isSortable = nonSortableColumns && !nonSortableColumns.includes(component);

    const renderSortIcon = () => (
        <SortableImage
            role="button"
            tabIndex={isSortable ? 0 : -1}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            aria-label={`${t('dynamic-components:columnAction.sortBy')} ${label}`}
            onClick={() => isSortable && onSortChange(name)}
            onKeyDown={(e) =>
                isSortable && (e.key === 'Enter' || e.key === ' ') && onSortChange(name)
            }
        >
            {(state.isFocused || state.isHovered || sortKey === name) && <span>{sortIcon}</span>}
        </SortableImage>
    );

    const onRemoveFieldConfirmModal = useCallback(
        (value: ColumnDefinition['name']) => {
            modal.confirm({
                centered: true,
                title: t('dynamic-components:deleteField.title'),
                icon: <ExclamationCircleFilled style={{color: '#ff4d4f'}} />,
                content: t('dynamic-components:deleteField.content'),
                okText: t('dynamic-components:deleteField.delete'),
                okType: 'danger',
                cancelText: t('dynamic-components:deleteField.cancel'),
                okButtonProps: {id: 'delete-field', type: 'primary', size: 'small'},
                cancelButtonProps: {size: 'small'},
                async onOk() {
                    await onColumnAction({action: TableAction.DELETE_COLUMN, value});
                },
            });
        },
        [modal, onColumnAction]
    );

    const items: any = ColumnOptions(
        name,
        component,
        columnIndex,
        canDeleteColumn,
        isTemplateField,
        nonSortableColumns ?? [],
        canEditColumn && editable
    );

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            e.stopPropagation();
            const dropdownTrigger = e.currentTarget.querySelector('.anticon') as HTMLElement;
            if (dropdownTrigger) {
                dropdownTrigger.click();
            }
        }
    };

    const onClick = useCallback(
        (e: any) => {
            const [actionName, ...columnNameParts] = e.key.split('_');
            const value = columnNameParts.join('_');
            if (actionName && value) {
                if (SORT_KEYS.includes(actionName)) {
                    onSortChange(value);
                } else if (actionName === 'remove') {
                    onRemoveFieldConfirmModal(value);
                } else if (actionName === 'edit') {
                    setManageColumnModal(true);
                } else {
                    onColumnAction({action: actionName, value});
                }
            }
        },
        [onSortChange, onRemoveFieldConfirmModal, onColumnAction]
    );

    return (
        <HeaderStyles>
            <SortableHeaderColumn
                isRowReverse={RIGHT_ALIGNED_FIELDS.includes(component)}
                onMouseOver={handleFocus}
                onMouseOut={handleBlur}
            >
                <Row aria-label={`${label} column`}>
                    <HeaderText tabIndex={0} ellipsis={{tooltip: `${label}`}}>
                        {label}
                    </HeaderText>
                    <Dropdown
                        menu={{items, onClick}}
                        placement="bottomRight"
                        arrow={{pointAtCenter: true}}
                        trigger={['click']}
                    >
                        <div
                            tabIndex={0}
                            role="button"
                            aria-label={`${t(
                                'dynamic-components:columnAction.optionsFor'
                            )} ${label}`}
                            onKeyDown={handleKeyDown}
                        >
                            <DownOutlined />
                        </div>
                    </Dropdown>
                </Row>
                {isSortable && renderSortIcon()}
                {contextHolder}
            </SortableHeaderColumn>
            {isManageColumnModalOpen && (
                <ManageColumnModal
                    editColumnSchema={column}
                    setManageColumnModal={setManageColumnModal}
                />
            )}
        </HeaderStyles>
    );
});
