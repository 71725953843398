import {Select} from 'antd';
import {DefaultOptionType} from 'antd/es/select';
import {useCallback, useEffect, useState} from 'react';

import {useFieldOperations} from '../../hooks/useFieldOperations';
import {SelectFieldProps} from '../Fields';
import {SingleSelectText} from '../View/Select';

export const SingleSelect = ({
    attributes,
    fieldValue,
    required,
    validations,
    onChange,
    forwardedRef,
}: SelectFieldProps) => {
    const [dropDownOptions, setDropDownOptions] = useState<DefaultOptionType[]>([]);
    const {inputValue, handleOnBlur, handleOnChange} = useFieldOperations(
        fieldValue,
        required,
        validations,
        onChange
    );

    const optionBuilder = useCallback(() => {
        const selectTextProps = {
            attributes,
            fieldValue,
            required,
            validations,
            onChange,
            forwardedRef,
        };
        const options: DefaultOptionType[] = [];
        const enumConfig = attributes.enumConfig;
        if (enumConfig) {
            for (const item in enumConfig) {
                const isEnumDeleted = enumConfig[item].isDeleted ?? false; // backward compatiblity for older schemas
                if (!isEnumDeleted) {
                    options.push({
                        label: <SingleSelectText {...selectTextProps} fieldValue={item} />,
                        value: item,
                    });
                }
            }
        }

        setDropDownOptions(options);
    }, [attributes]);

    useEffect(() => {
        optionBuilder();
    }, [optionBuilder]);

    const onChangeSelect = (value: string) => {
        if (!value) return;
        handleOnChange(value);
    };

    return (
        <Select
            value={inputValue?.toString()}
            options={dropDownOptions}
            onChange={onChangeSelect}
            onBlur={handleOnBlur}
            autoFocus
            ref={forwardedRef}
            style={{flexGrow: 1}}
        />
    );
};
