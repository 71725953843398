import {$patchStyleText} from '@lexical/selection';
import {Add, Minus} from '@sharefiledev/icons';
import {$getSelection, LexicalEditor} from 'lexical';
import {useCallback, useEffect, useState} from 'react';

import {t} from '../../../../../utils';
import {FontSizeWrapper} from './FontSize.styled';

const MIN_ALLOWED_FONT_SIZE = 8;
const MAX_ALLOWED_FONT_SIZE = 72;
const DEFAULT_FONT_SIZE = 15;

enum updateFontSizeType {
    increment = 1,
    decrement,
}

export default function FontSize({
    selectionFontSize,
    disabled,
    editor,
}: {
    selectionFontSize: string;
    disabled: boolean;
    editor: LexicalEditor;
}) {
    const [inputValue, setInputValue] = useState<string>(selectionFontSize);
    const [inputChangeFlag, setInputChangeFlag] = useState<boolean>(false);

    const calculateNextFontSize = (
        currentFontSize: number,
        updateType: updateFontSizeType | null
    ) => {
        if (!updateType) {
            return currentFontSize;
        }

        let updatedFontSize: number = currentFontSize;
        switch (updateType) {
            case updateFontSizeType.decrement:
                switch (true) {
                    case currentFontSize > MAX_ALLOWED_FONT_SIZE:
                        updatedFontSize = MAX_ALLOWED_FONT_SIZE;
                        break;
                    case currentFontSize >= 48:
                        updatedFontSize -= 12;
                        break;
                    case currentFontSize >= 24:
                        updatedFontSize -= 4;
                        break;
                    case currentFontSize >= 14:
                        updatedFontSize -= 2;
                        break;
                    case currentFontSize >= 9:
                        updatedFontSize -= 1;
                        break;
                    default:
                        updatedFontSize = MIN_ALLOWED_FONT_SIZE;
                        break;
                }
                break;

            case updateFontSizeType.increment:
                switch (true) {
                    case currentFontSize < MIN_ALLOWED_FONT_SIZE:
                        updatedFontSize = MIN_ALLOWED_FONT_SIZE;
                        break;
                    case currentFontSize < 12:
                        updatedFontSize += 1;
                        break;
                    case currentFontSize < 20:
                        updatedFontSize += 2;
                        break;
                    case currentFontSize < 36:
                        updatedFontSize += 4;
                        break;
                    case currentFontSize <= 60:
                        updatedFontSize += 12;
                        break;
                    default:
                        updatedFontSize = MAX_ALLOWED_FONT_SIZE;
                        break;
                }
                break;

            default:
                break;
        }
        return updatedFontSize;
    };

    const updateFontSizeInSelection = useCallback(
        (newFontSize: string | null, updateType: updateFontSizeType | null) => {
            const getNextFontSize = (prevFontSize: string | null): string => {
                if (!prevFontSize) {
                    prevFontSize = `${DEFAULT_FONT_SIZE}px`;
                }
                prevFontSize = prevFontSize.slice(0, -2);
                const nextFontSize = calculateNextFontSize(Number(prevFontSize), updateType);
                return `${nextFontSize}px`;
            };

            editor.update(() => {
                if (editor.isEditable()) {
                    const selection = $getSelection();
                    if (selection !== null) {
                        $patchStyleText(selection, {
                            'font-size': newFontSize || getNextFontSize,
                        });
                    }
                }
            });
        },
        [editor]
    );

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const inputValueNumber = Number(inputValue);

        if (['e', 'E', '+', '-'].includes(e.key) || isNaN(inputValueNumber)) {
            e.preventDefault();
            setInputValue('');
            return;
        }
        setInputChangeFlag(true);
        if (e.key === 'Enter' || e.key === 'Tab' || e.key === 'Escape') {
            e.preventDefault();

            updateFontSizeByInputValue(inputValueNumber);
        }
    };

    const handleInputBlur = () => {
        if (inputValue !== '' && inputChangeFlag) {
            const inputValueNumber = Number(inputValue);
            updateFontSizeByInputValue(inputValueNumber);
        }
    };

    const handleButtonClick = (updateType: updateFontSizeType) => {
        if (inputValue !== '') {
            const nextFontSize = calculateNextFontSize(Number(inputValue), updateType);
            updateFontSizeInSelection(String(nextFontSize) + 'px', null);
        } else {
            updateFontSizeInSelection(null, updateType);
        }
    };

    const updateFontSizeByInputValue = (inputValueNumber: number) => {
        let updatedFontSize = inputValueNumber;
        if (inputValueNumber > MAX_ALLOWED_FONT_SIZE) {
            updatedFontSize = MAX_ALLOWED_FONT_SIZE;
        } else if (inputValueNumber < MIN_ALLOWED_FONT_SIZE) {
            updatedFontSize = MIN_ALLOWED_FONT_SIZE;
        }

        setInputValue(String(updatedFontSize));
        updateFontSizeInSelection(String(updatedFontSize) + 'px', null);
        setInputChangeFlag(false);
    };

    useEffect(() => {
        setInputValue(selectionFontSize);
    }, [selectionFontSize]);

    return (
        <FontSizeWrapper>
            <button
                type="button"
                disabled={
                    disabled ||
                    (selectionFontSize !== '' && Number(inputValue) <= MIN_ALLOWED_FONT_SIZE)
                }
                onClick={() => handleButtonClick(updateFontSizeType.decrement)}
                className="toolbar-item font-decrement"
                aria-label={t('dynamic-components:richText.areaLabel.decrement')}
                title={t('dynamic-components:richText.title.decrement')}
            >
                <Minus />
            </button>

            <input
                type="number"
                value={inputValue}
                disabled={disabled}
                className="toolbar-item font-size-input"
                min={MIN_ALLOWED_FONT_SIZE}
                max={MAX_ALLOWED_FONT_SIZE}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyPress}
                onBlur={handleInputBlur}
            />

            <button
                type="button"
                disabled={
                    disabled ||
                    (selectionFontSize !== '' && Number(inputValue) >= MAX_ALLOWED_FONT_SIZE)
                }
                onClick={() => handleButtonClick(updateFontSizeType.increment)}
                className="toolbar-item font-increment"
                aria-label={t('dynamic-components:richText.areaLabel.increment')}
                title={t('dynamic-components:richText.title.increment')}
            >
                <Add />
            </button>
        </FontSizeWrapper>
    );
}
