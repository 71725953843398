import {$isCodeNode, CODE_LANGUAGE_MAP, getCodeLanguages} from '@lexical/code';
import {$isLinkNode, TOGGLE_LINK_COMMAND} from '@lexical/link';
import {$isListNode, ListNode} from '@lexical/list';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$isHeadingNode} from '@lexical/rich-text';
import {$getSelectionStyleValueForProperty} from '@lexical/selection';
import {$isTableSelection} from '@lexical/table';
import {$findMatchingParent, $getNearestNodeOfType, IS_APPLE, mergeRegister} from '@lexical/utils';
import {colorPalette} from '@sharefiledev/antd-config';
import {
    Bold,
    ChevronDown,
    Italic,
    LinkChain,
    Redo,
    Strikethrough,
    Underline,
    Undo,
} from '@sharefiledev/icons';
import {
    $getNodeByKey,
    $getSelection,
    $isElementNode,
    $isRangeSelection,
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    ElementFormatType,
    FORMAT_TEXT_COMMAND,
    REDO_COMMAND,
    SELECTION_CHANGE_COMMAND,
    UNDO_COMMAND,
} from 'lexical';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {createPortal} from 'react-dom';

import {FieldProperties} from '../../../../../engine/types';
import {useRichText} from '../../../../../layouts/table/context/RichText';
import {t} from '../../../../../utils';
import FontSize from './FontSize';
import {
    BlockOptionsDropdownList,
    blockTypeToBlockName,
    Divider,
    ElementFormatDropdown,
    FloatingLinkEditor,
    getSelectedNode,
    LowPriority,
    renderIcon,
    supportedBlockTypes,
    ToolbarButton,
} from './helper';

const Select = ({onChange, className, options, value}: any) => {
    return (
        <select className={className} onChange={onChange} value={value}>
            <option hidden value="" />
            {options.map((option: any) => (
                <option key={option} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
};

interface EditorToolbarProp {
    editorProp: FieldProperties;
}

export default function ToolbarPlugin({editorProp}: EditorToolbarProp) {
    const {isExpanded} = editorProp;
    const [editor] = useLexicalComposerContext();
    const toolbarRef = useRef(null);
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    const [blockType, setBlockType] = useState<keyof typeof blockTypeToBlockName>('paragraph');
    const [selectedElementKey, setSelectedElementKey] = useState<string | null>(null);
    const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] = useState(false);
    const [codeLanguage, setCodeLanguage] = useState('');

    const [elementFormat, setElementFormat] = useState<ElementFormatType>('left');
    const [isLink, setIsLink] = useState(false);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [fontSize, setFontSize] = useState<string>('15px');
    const {setDisableOnPopoverChange} = useRichText();

    const updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            const element =
                anchorNode.getKey() === 'root'
                    ? anchorNode
                    : anchorNode.getTopLevelElementOrThrow();
            const elementKey = element.getKey();
            const elementDOM = editor.getElementByKey(elementKey);
            if (elementDOM !== null) {
                setSelectedElementKey(elementKey);
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
                    const type = parentList ? parentList.getListType() : element.getListType();
                    // tempType = type;
                    setBlockType(type);
                } else {
                    const type = $isHeadingNode(element) ? element.getTag() : element.getType();
                    // tempType = type;
                    if (type in blockTypeToBlockName) {
                        setBlockType(type as keyof typeof blockTypeToBlockName);
                    }
                    if ($isCodeNode(element)) {
                        const language = element.getLanguage() as keyof typeof CODE_LANGUAGE_MAP;
                        setCodeLanguage(language ? CODE_LANGUAGE_MAP[language] || language : '');
                        return;
                    }
                }
            }
            // Update text format
            setIsBold(selection.hasFormat('bold'));
            setIsItalic(selection.hasFormat('italic'));
            setIsUnderline(selection.hasFormat('underline'));
            setIsStrikethrough(selection.hasFormat('strikethrough'));

            // setIsSubScript(selection.hasFormat('subscript'));
            // setIsSuperScript(selection.hasFormat('superscript'));

            // Update links
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
                return;
            }
            setIsLink(false);
            if ($isRangeSelection(selection) || $isTableSelection(selection)) {
                setFontSize($getSelectionStyleValueForProperty(selection, 'font-size', '15px'));
            }
            let matchingParent;
            if ($isLinkNode(parent)) {
                // If node is a link, we need to fetch the parent paragraph node to set format
                matchingParent = $findMatchingParent(
                    node,
                    (parentNode) => $isElementNode(parentNode) && !parentNode.isInline()
                );
            }
            setElementFormat(
                $isElementNode(matchingParent)
                    ? matchingParent.getFormatType()
                    : $isElementNode(node)
                    ? node.getFormatType()
                    : parent?.getFormatType() || 'left'
            );
        }
    }, [editor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({editorState}) => {
                editorState.read(() => {
                    updateToolbar();
                });
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    updateToolbar();
                    return false;
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_UNDO_COMMAND,
                (payload) => {
                    setCanUndo(payload);
                    return false;
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_REDO_COMMAND,
                (payload) => {
                    setCanRedo(payload);
                    return false;
                },
                LowPriority
            )
        );
    }, [editor, updateToolbar]);

    const codeLanguges = useMemo(() => getCodeLanguages(), []);
    const onCodeLanguageSelect = useCallback(
        (e: {target: {value: string}}) => {
            editor.update(() => {
                if (selectedElementKey === null) return;
                const node = $getNodeByKey(selectedElementKey);
                if ($isCodeNode(node)) {
                    node.setLanguage(e.target.value);
                }
            });
        },
        [editor, selectedElementKey]
    );

    const insertLink = useCallback(() => {
        if (!isLink) {
            return editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
        }
        return editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }, [editor, isLink]);

    return (
        <div className="toolbar" ref={toolbarRef}>
            <ToolbarButton
                onClickEvent={() => editor.dispatchCommand(UNDO_COMMAND, undefined)}
                isSelected={false}
                ariaLabel={t('dynamic-components:richText.areaLabel.undo')}
                icon={<Undo size={18} className={`${canUndo ? '' : 'toolbar-item-disabled'}`} />}
                isDisabled={!canUndo || !editorProp.editable}
                className="toolbar-item spaced"
                title={t(`dynamic-components:richText.title.${IS_APPLE ? 'undoMac' : 'undoWin'}`)}
            />
            <ToolbarButton
                onClickEvent={() => editor.dispatchCommand(REDO_COMMAND, undefined)}
                isSelected={false}
                ariaLabel={t('dynamic-components:richText.areaLabel.redo')}
                icon={<Redo size={18} className={`${canRedo ? '' : 'toolbar-item-disabled'}`} />}
                isDisabled={!canRedo || !editorProp.editable}
                className="toolbar-item"
                title={t(`dynamic-components:richText.title.${IS_APPLE ? 'redoMac' : 'redoWin'}`)}
            />
            <Divider />
            {supportedBlockTypes.has(blockType) && (
                <>
                    <ToolbarButton
                        onClickEvent={() => {
                            setShowBlockOptionsDropDown(!showBlockOptionsDropDown);
                            setDisableOnPopoverChange(true);
                        }}
                        isSelected={false}
                        ariaLabel={t('dynamic-components:richText.areaLabel.formattingOptions')}
                        icon={
                            <>
                                <span className="icon block-type ">{renderIcon[blockType]}</span>
                                <span className="text">{blockTypeToBlockName[blockType]}</span>
                                <ChevronDown size={20} />
                            </>
                        }
                        isDisabled={false || !editorProp.editable}
                        className="toolbar-item block-controls"
                        title=""
                    />
                    {showBlockOptionsDropDown &&
                        createPortal(
                            <BlockOptionsDropdownList
                                editor={editor}
                                blockType={blockType}
                                toolbarRef={toolbarRef}
                                setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
                                isDisabled={!editorProp.editable}
                            />,
                            document.body
                        )}

                    <Divider />
                    <FontSize
                        selectionFontSize={fontSize.slice(0, -2)}
                        editor={editor}
                        disabled={false || !!editorProp.editable}
                    />

                    <Divider />
                </>
            )}
            {blockType === 'code' ? (
                <>
                    <Select
                        className="toolbar-item code-language"
                        onChange={onCodeLanguageSelect}
                        options={codeLanguges}
                        value={codeLanguage}
                        isDisabled={!editorProp.editable}
                    />
                    <ChevronDown size={20} />
                </>
            ) : (
                <>
                    <ToolbarButton
                        onClickEvent={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')}
                        isSelected={isBold}
                        ariaLabel={t('dynamic-components:richText.areaLabel.bold')}
                        icon={
                            <Bold
                                size={18}
                                color={isBold ? colorPalette.lavender1 : colorPalette.neutral1}
                            />
                        }
                        isDisabled={!editorProp.editable}
                        className="toolbar-item spaced"
                        title={t(
                            `dynamic-components:richText.title.${IS_APPLE ? 'boldMac' : 'boldWin'}`
                        )}
                    />
                    <ToolbarButton
                        onClickEvent={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')}
                        isSelected={isItalic}
                        ariaLabel={t('dynamic-components:richText.areaLabel.italics')}
                        icon={<Italic size={18} />}
                        isDisabled={!editorProp.editable}
                        className="toolbar-item spaced"
                        title={t(
                            `dynamic-components:richText.title.${
                                IS_APPLE ? 'italicMac' : 'italicWin'
                            }`
                        )}
                    />
                    <ToolbarButton
                        onClickEvent={() =>
                            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
                        }
                        isSelected={isUnderline}
                        ariaLabel={t('dynamic-components:richText.areaLabel.underline')}
                        icon={<Underline size={18} />}
                        isDisabled={!editorProp.editable}
                        className="toolbar-item spaced"
                        title={t(
                            `dynamic-components:richText.title.${
                                IS_APPLE ? 'underlineMac' : 'underlineWin'
                            }`
                        )}
                    />
                    <ToolbarButton
                        onClickEvent={() =>
                            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')
                        }
                        isSelected={isStrikethrough}
                        ariaLabel={t('dynamic-components:richText.areaLabel.strikethrough')}
                        icon={<Strikethrough size={18} />}
                        isDisabled={!editorProp.editable}
                        className="toolbar-item spaced"
                        title={t('dynamic-components:richText.title.strikeThrough')}
                    />
                    {/* Commenting below for time being as per product feedback */}
                    {/* <ToolbarButton
                        onClickEvent={() =>
                            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'subscript')
                        }
                        isSelected={isSubScript}
                        ariaLabel={t('dynamic-components:richText.areaLabel.subscript')}
                        icon={<Subscript size={18} />}
                        isDisabled={false}
                        className="toolbar-item spaced"
                    />
                    <ToolbarButton
                        onClickEvent={() =>
                            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'superscript')
                        }
                        isSelected={isSuperScript}
                        ariaLabel={t('dynamic-components:richText.areaLabel.superscript')}
                        icon={<Superscript size={18} />}
                        isDisabled={false}
                        className="toolbar-item spaced"
                    /> */}
                    <ToolbarButton
                        onClickEvent={insertLink}
                        isSelected={isLink}
                        ariaLabel={t('dynamic-components:richText.areaLabel.link')}
                        icon={<LinkChain size={21} />}
                        isDisabled={!editorProp.editable}
                        className="toolbar-item spaced"
                        title={t('dynamic-components:richText.title.link')}
                    />
                    {isLink &&
                        createPortal(
                            <FloatingLinkEditor
                                editor={editor}
                                isExpanded={isExpanded}
                                isDisabled={!editorProp.editable}
                            />,
                            document.parentElement || document.body
                        )}

                    <Divider />
                    <ElementFormatDropdown
                        disabled={!editorProp.editable}
                        value={elementFormat}
                        editor={editor}
                    />
                </>
            )}
        </div>
    );
}
