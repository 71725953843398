import {useCallback, useEffect, useRef, useState} from 'react';

import {COLUMN_WIDTH} from '../../../enum';
import {ColumnDefinition, TableColumnRenderer} from '../DataTable';
import FieldMapper from '../Fields/Mapper';
import {TextField} from '../Fields/View/TextField';
import {Header, SortDirection, Sortobj} from '../header';
import {AddColumn} from '../header/column/AddColumn';

const isDesktop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return !userAgent.match(/mobile|tablet|phone/) || window.innerWidth >= 768;
};

const shouldFixed = (index: number) => (isDesktop() ? (index === 0 ? 'left' : false) : false);

const useGenerateColumns = (
    filteredColumn: ColumnDefinition[],
    sort: Sortobj | undefined,
    canAddColumn: boolean
) => {
    const [columns, setColumns] = useState<TableColumnRenderer[]>([]);
    const columnWidthsRef = useRef<Record<string, number>>({});
    const handleResize = useCallback(
        (
                key: string,
                index: number,
                columnWidthsRef: React.MutableRefObject<Record<string, number>>
            ) =>
            (_: React.SyntheticEvent<Element>, {size: {width}}: {size: {width: number}}) => {
                if (width < COLUMN_WIDTH.MIN_WIDTH || width > COLUMN_WIDTH.MAX_WIDTH) return;
                setColumns((prevColumns) => {
                    const nextColumns = [...prevColumns];
                    nextColumns[index] = {...nextColumns[index], width};
                    return nextColumns;
                });
                columnWidthsRef.current[key] = width;
            },

        []
    );
    const generateColumns = (
        columns: ColumnDefinition[],
        sort: Sortobj | undefined,
        canAddColumn: boolean
    ) => {
        // Function to get the ARIA sort attribute
        const getHeaderCellProps = (columnName: string) => ({
            'aria-sort':
                sort?.sortKey === columnName
                    ? sort.direction === SortDirection.DESC
                        ? 'descending'
                        : 'ascending'
                    : 'none',
        });
        // Generate column configuration
        const newColumns: TableColumnRenderer[] = columns.map(
            (column: ColumnDefinition, index: number) => {
                // Component to render cell content
                const CellComponent = FieldMapper.table?.view?.[column.component] ?? TextField;
                const {name, editable} = column;
                return {
                    title: <Header column={column} key={name} sort={sort} columnIndex={index} />,
                    dataIndex: name,
                    key: name,
                    editable: editable ?? true,
                    render: (cell: any, record: any) => {
                        const modifiedRecord = {...record, recordId: record?._id};
                        return (
                            <CellComponent
                                {...column}
                                fieldValue={cell}
                                key={index}
                                {...modifiedRecord}
                            />
                        );
                    },
                    width:
                        columnWidthsRef.current[name] ??
                        (index === 0 ? COLUMN_WIDTH.TITLE_DEFAULT : COLUMN_WIDTH.COLUMN_DEFAULT),
                    fixed: shouldFixed(index),

                    onHeaderCell: ({width}) => ({
                        ...getHeaderCellProps(name),
                        width,
                        onResize: handleResize(name, index, columnWidthsRef),
                    }),
                    onCell: (record: any) => ({
                        dataIndex: name,
                        record,
                        column: column,
                        editable: editable ?? true,
                        colKey: name,
                        colIndex: index,
                    }),
                    shouldCellUpdate: (record: any, prevRecord: any) =>
                        record[name] !== prevRecord[name],
                };
            }
        );

        if (canAddColumn)
            newColumns.push({
                title: <AddColumn />,
                dataIndex: 'add-column',
                editable: false,
                width: COLUMN_WIDTH.COLUMN_DEFAULT,
                key: 'add-column',
            });
        return newColumns;
    };

    const columnsData = useCallback(() => {
        const generatedColumns = generateColumns(filteredColumn, sort, canAddColumn);
        setColumns(generatedColumns);
    }, [filteredColumn, sort, canAddColumn]);

    useEffect(() => {
        columnsData();
    }, [columnsData]);

    return columns;
};

export default useGenerateColumns;
