import {colorPalette} from '@sharefiledev/antd-config';
import {Col, Dropdown, MenuProps, Row} from 'antd';
import {useEffect, useRef, useState} from 'react';

import {FileBrowserCounter} from '../../../../schema-components/plugins/file-browser-plugin/FileBrowserCounter';
import {FileBrowserTable} from '../../../../schema-components/plugins/file-browser-plugin/FileBrowserTable';
import {t} from '../../../../utils';
import {Icons} from '../../../../utils/icons/icon';
import {FileFieldProps} from '../Fields';
import {fileTableStyles, FileTableWrapper} from '../Fields.styled';

export const FileInputField = ({
    fieldValue,
    recordId,
    onInlineUpdateCompletion,
}: FileFieldProps) => {
    const rowRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(true);
    const fileRID = `${recordId}/${fieldValue}`;

    const handleClose = () => {
        setOpen(false);
        onInlineUpdateCompletion?.();
    };

    const toggleDropdown = (e: any) => {
        e.stopPropagation();
        handleClose();
    };

    const items: MenuProps['items'] = [
        {
            key: 'file-table',
            label: fieldValue ? (
                <FileTableWrapper
                    className="file-table-wrapper"
                    borderColor={colorPalette.neutral3}
                >
                    <FileBrowserTable
                        fileUploadComplete={handleClose}
                        fileDelete={handleClose}
                        containerRID={fileRID}
                        uploadBtn={{
                            type: 'link',
                            size: 'middle',
                            label: t('dynamic-components:fileBrowserPlugin.uploadBtnDropdown'),
                        }}
                    />
                </FileTableWrapper>
            ) : (
                <></>
            ),
            style: fileTableStyles,
        },
    ];

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const target = event.target as Node;

            const isOutsideRow = rowRef.current && !rowRef.current.contains(target);
            const isOutsideFileTableWrapper = !document
                .querySelector('.file-table-wrapper')
                ?.contains(target);
            const isOutsideModal = !document.querySelector('.ant-modal-content')?.contains(target);
            const isOutsideDrawer = !document
                .querySelector('.ant-drawer-content')
                ?.contains(target);

            if (isOutsideRow && isOutsideFileTableWrapper && isOutsideModal && isOutsideDrawer) {
                handleClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClose]);

    return (
        <div>
            <Dropdown
                menu={{items}}
                open={open}
                autoAdjustOverflow
                overlayStyle={{width: 400, zIndex: 1000}}
                trigger={['click']}
            >
                <Row ref={rowRef} onClick={toggleDropdown} className="padded-row">
                    <Col>
                        <Icons.Paperclip color={colorPalette.neutral5} />
                    </Col>
                    <Col>
                        {fieldValue ? (
                            <FileBrowserCounter containerRID={fileRID} />
                        ) : (
                            <div>{t('dynamic-components:fileBrowserPlugin.empty.cellText')}</div>
                        )}
                    </Col>
                </Row>
            </Dropdown>
        </div>
    );
};
