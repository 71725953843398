import {ComponentType} from 'react';

interface WithDefaultSizeProps {
    size?: number;
}

const withDefaultSize = <P extends WithDefaultSizeProps>(WrappedComponent: ComponentType<P>) => {
    return ({size = 24, ...props}: Omit<P, 'size'> & WithDefaultSizeProps) => {
        return <WrappedComponent {...(props as P)} size={size} />;
    };
};

export default withDefaultSize;
