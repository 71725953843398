import {useCallback, useState} from 'react';

/**
 * Custom hook for managing focus and hover states.
 * @returns {Object} Object containing state and handlers for focus and hover.
 */
const useFocusAndHover = () => {
    const [state, setState] = useState({isFocused: false, isHovered: false});

    const handleFocus = useCallback(() => {
        setState((prevState) => ({...prevState, isFocused: true}));
    }, []);

    const handleBlur = useCallback(() => {
        setState((prevState) => ({...prevState, isFocused: false}));
    }, []);

    const handleMouseEnter = useCallback(() => {
        setState((prevState) => ({...prevState, isHovered: true}));
    }, []);

    const handleMouseLeave = useCallback(() => {
        setState((prevState) => ({...prevState, isHovered: false}));
    }, []);

    return {state, handleFocus, handleBlur, handleMouseEnter, handleMouseLeave};
};

export default useFocusAndHover;
