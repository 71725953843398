import {Typography} from 'antd';

interface ContactOptionProps {
    contact: {
        firstName: string;
        lastName: string;
        email: string;
    };
}

export const ContactOption: React.FC<ContactOptionProps> = ({contact}) => {
    return (
        <div>
            <div>
                <Typography.Text
                    strong
                    ellipsis
                >{`${contact.firstName} ${contact.lastName}`}</Typography.Text>
            </div>
            <Typography.Text ellipsis type="secondary" style={{fontSize: '12px'}}>
                {contact.email}
            </Typography.Text>
        </div>
    );
};
