import styled from '@emotion/styled';
import {Flex} from 'antd';

export const ToolbarWrapper = styled(Flex)`
    margin: 0 6px 16px 6px;
    justify-content: space-between;

    .anticon {
        position: relative;
        top: 2px;
    }
`;
