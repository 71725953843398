import {createContext, Dispatch, SetStateAction, useContext, useMemo, useState} from 'react';

type EditorValueType = {
    rtFieldName: string | null;
    rtFieldValue: any;
};

type RichTextContentProps = {
    textEditors: EditorValueType[];
    setTextEditors: Dispatch<SetStateAction<EditorValueType[]>>;
    reload: boolean;
    setReload: Dispatch<SetStateAction<boolean>>;
    disableOnPopoverChange: boolean;
    setDisableOnPopoverChange: Dispatch<SetStateAction<boolean>>;
};

type RichTextProviderProps = {
    children: JSX.Element;
};

const RichTextContext = createContext({} as RichTextContentProps);

export const RichTextContextProvider = ({children}: RichTextProviderProps) => {
    const [textEditors, setTextEditors] = useState<EditorValueType[]>([]);
    const [reload, setReload] = useState(false);
    const [disableOnPopoverChange, setDisableOnPopoverChange] = useState(false);

    const contextValue = useMemo(() => {
        return {
            textEditors,
            setTextEditors,
            reload,
            setReload,
            disableOnPopoverChange,
            setDisableOnPopoverChange,
        };
    }, [textEditors, reload, disableOnPopoverChange]);

    return <RichTextContext.Provider value={contextValue}>{children}</RichTextContext.Provider>;
};

export const useRichText = (): RichTextContentProps => {
    return useContext(RichTextContext);
};
