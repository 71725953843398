import {storageExtensionSlots, StorageFileTableParams} from '@sharefiledev/storage-plugin-pilet';

import {useAppContext} from '../../../AppContext';

interface Props {
    containerRID: string;
}

export const FileBrowserCounter = ({containerRID}: Props) => {
    const {
        piletApi: {Extension},
    } = useAppContext();

    const filesTableParams: StorageFileTableParams = {
        containerRID,
    };

    return <Extension name={storageExtensionSlots.Count} params={filesTableParams} />;
};
