export const SortUpArrow = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
        >
            <g clipPath="url(#clip0_5160_68716)">
                <path
                    d="M2.33331 3.5H6.41665"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.33331 7H6.41665"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.33331 10.5H7.58331"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.75 5.25L10.5 3.5L12.25 5.25"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.5 3.5V10.5"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_5160_68716">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const SortDownArrow = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
        >
            <g clipPath="url(#clip0_5107_104329)">
                <path
                    d="M2.33337 3.5H7.58337"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.33337 7H6.41671"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.33337 10.5H6.41671"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.75 8.75L10.5 10.5L12.25 8.75"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.5 3.5V10.5"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_5107_104329">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
