import {LoadingPrimary} from '@sharefiledev/flow-web';

import {IconWrapperLoader} from './Loader.styled';

interface LoaderProps {
    height?: string;
}

export const Loader = (props: LoaderProps) => {
    return (
        <>
            <IconWrapperLoader data-testid="loader" height={props.height}>
                <LoadingPrimary initialHeight={props.height} />
            </IconWrapperLoader>
        </>
    );
};
