import {ContactListType} from '@citrite/sf-api';
import {Close} from '@sharefiledev/icons';
import {Select, Spin, Tag} from 'antd';
import {debounce} from 'lodash';
import React, {ReactNode, useCallback, useEffect} from 'react';
import {useAsync} from 'react-async-hook';

import {loadAssigneeUsers} from '../../../../api/sfApi';
import {COLORS, REMOVE_TAG_BUTTON_CLASS} from '../../../../constants/constants';
import {FieldProperties, InputObj, TagRender} from '../../../../engine/types';
import {t} from '../../../../utils';
import {useContactSearch} from '../../../../utils/hooks/useContactSearch/useContactSearch';
import {addUsersToProjectFromRelatedResources} from '../../../../utils/projectManagement/addUsertoProject';
import {getParentResourceRID} from '../../../../utils/utils';
import {
    AssigneeMultiUserFieldWrapper,
    StyledSpanVerticalAlign,
    StyledTagRemoveBtn,
} from '../AccountUserMultiSelectField/AccountUserMultiSelect.styled';
import {ContactOption} from './ContactOption';

export type ContactOptionType = {
    label: ReactNode;
    value: string;
    details: any;
};
export interface ContactMultiSelectProps extends FieldProperties {
    validations?: {
        type: [string];
        limit?: {
            value: number;
            message: string;
        };
    };
    attributes?: {
        userGroup?: string;
        allowMultiSelect?: boolean;
    };
    isFilter?: boolean;
}

export const ContactMultiSelect: React.FC<ContactMultiSelectProps> = ({
    value,
    onChange,
    attributes,
    label,
    disabled,
    isFilter = false,
    record,
    forwardedRef,
}) => {
    const [term, setTerm] = React.useState('');
    const [contactsList, setContactsList] = React.useState<ContactOptionType[]>([]);
    const [selectedContacts, setSelectedContacts] = React.useState<ContactOptionType[]>([]);

    const {allowMultiSelect = true, userGroup = ContactListType.AllUsers} = attributes || {
        allowMultiSelect: true,
        userGroup: ContactListType.AllUsers,
    };

    /**
     * This hook is using for reset the values
     * when user click clear in filter section
     */
    useEffect(() => {
        if (!value || (value && Array.isArray(value) && value.length === 0)) {
            setContactsList([]);
            setSelectedContacts([]);
        }
    }, [value]);

    const loadAssignees = useCallback(async (ids: string[]) => {
        const result = await loadAssigneeUsers(ids);
        const contactOptions = result
            .map((contact: any) => ({
                firstName: contact.FirstName,
                lastName: contact.LastName,
                email: contact.Email,
                id: contact.Id,
            }))
            .map(convertToOption);
        setContactsList(contactOptions);
        setSelectedContacts(contactOptions);
    }, []);

    useEffect(() => {
        if (value && Array.isArray(value) && value.length > 0) {
            loadAssignees(value);
        } else {
            setContactsList([]);
        }
    }, [loadAssignees]);

    const debouncedSearch = useCallback(
        debounce((value) => {
            setTerm(value);
        }, 500),
        [setTerm]
    );

    const {searchContacts} = useContactSearch();
    const asyncUserFind = useAsync(
        async (filterTerm: string) => {
            if (filterTerm.length < 2) {
                return [];
            }
            const result: any = await searchContacts(
                filterTerm,
                userGroup ?? ContactListType.AllUsers
            );
            const contactOptions: ContactOptionType[] = result.map(convertToOption);
            // ?.filter((option) => {
            //     if (Array.isArray(value)) {
            //         return !value.some((id: any) => id === option.id);
            //     }
            //     return true;
            // })
            // .map(convertToOption);
            setContactsList(contactOptions);
            return contactOptions;
        },
        [term],
        {executeOnMount: false}
    );

    const SelectedUserLabel: React.FC<any> = ({firstName, lastName, email}) => (
        <>{firstName || lastName ? `${firstName} ${lastName}` : email ?? ''}</>
    );

    const renderTag: TagRender = ({label, value, closable, onClose}) => {
        const contact = selectedContacts?.find(
            (option: ContactOptionType) => option.value === value
        )?.details;

        return (
            <Tag
                closeIcon={
                    <StyledTagRemoveBtn
                        className={REMOVE_TAG_BUTTON_CLASS}
                        aria-label={t(
                            'dynamic-components:manageField.fields.userMultiSelect.removeBtnAriaLabel',
                            {
                                userName: contact ? contact.email : '',
                            }
                        )}
                        onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
                            if (e.key === 'Enter') {
                                e.stopPropagation();
                                onClose();
                            }
                        }}
                        type="button"
                    >
                        <StyledSpanVerticalAlign>
                            <Close size={16} />
                        </StyledSpanVerticalAlign>
                    </StyledTagRemoveBtn>
                }
                bordered={false}
                style={{
                    marginInlineEnd: 4,
                    background: COLORS['default'].background,
                    color: COLORS['default'].color,
                }}
                closable={closable}
                onClose={onClose}
            >
                {contact ? <SelectedUserLabel {...contact} /> : label}
            </Tag>
        );
    };

    const addEmployeeToProject = async (updatedList: any) => {
        if (!isFilter && userGroup !== ContactListType.Clients) {
            const {_id} = record;
            const tableRID = getParentResourceRID(_id);
            const projectRID = getParentResourceRID(tableRID);
            await addUsersToProjectFromRelatedResources(projectRID, updatedList);
        }
    };

    const updateList = (contact: any) => {
        const data: InputObj = {value: [], errorMessage: ''};
        if (allowMultiSelect) {
            setSelectedContacts((prevList) => {
                // Check if the new object already exists in the list
                const index = prevList.findIndex(
                    (item: ContactOptionType) => item.value === contact.value
                );

                let updatedList: ContactOptionType[] = [];
                if (index !== -1) {
                    // If it exists, remove it from the list
                    updatedList = prevList.filter((_, i) => i !== index);
                } else {
                    // If it doesn't exist, add the new object to the list
                    updatedList = [...prevList, contact];
                }
                const userIds = updatedList.map((e: ContactOptionType) => e.value);
                if (index === -1) {
                    addEmployeeToProject(userIds);
                }
                // Call the callback function with the updated list
                if (onChange) {
                    onChange({...data, value: userIds});
                }
                return updatedList;
            });
        } else {
            setSelectedContacts([contact]);
            addEmployeeToProject([contact.value]);
            if (onChange) {
                onChange({...data, value: [contact.value]});
            }
        }
    };

    return (
        <AssigneeMultiUserFieldWrapper isEditable={!disabled}>
            <div className="contact-multi-select">
                <Select
                    mode={allowMultiSelect ? 'multiple' : undefined}
                    autoFocus
                    data-testid="contact-multiple-select"
                    labelInValue
                    filterOption={false}
                    placeholder={t('dynamic-components:userSelect.search')}
                    onSearch={debouncedSearch}
                    notFoundContent={asyncUserFind.loading ? <Spin size="small" /> : null}
                    style={{width: '100%'}}
                    showSearch
                    maxTagCount="responsive"
                    onSelect={(_, option) => updateList(option)}
                    onDeselect={updateList}
                    value={selectedContacts?.map((option: ContactOptionType) => ({
                        label: <SelectedUserLabel {...option.details} />,
                        value: option.details.id!,
                    }))}
                    ref={forwardedRef}
                    aria-label={`${label} ${allowMultiSelect ? 'multi-select' : 'select'}`}
                    options={contactsList}
                    tagRender={renderTag}
                />
            </div>
        </AssigneeMultiUserFieldWrapper>
    );
};

export const convertToOption = (contact: any): ContactOptionType => {
    return {
        label: <ContactOption contact={contact} />,
        value: contact.id!,
        details: contact,
    };
};
