import {logger} from '../utils/logger';

interface Window {
    pendo?: any;
}

export const pendoSendTrackEvent = (event: string, metadata: any = {}) => {
    try {
        const pendo = (window as unknown as Window).pendo;
        if (!pendo?.track) {
            return;
        }
        const metadataWithProductName = {
            ...metadata,
            product: 'ShareFile - DataTable',
            type: event,
        };
        pendo.track(event, metadataWithProductName);
    } catch (error) {
        logger.logError('error occred while raising pendo event');
    }
};
