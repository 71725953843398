import {t} from '../../../../utils/translate';

export const ColumnOptions = (
    name: string,
    component: string,
    columnIndex: number,
    canDeleteColumn: boolean,
    isTemplateField: boolean,
    nonSortableColumns: string[],
    canEditColumn?: boolean
) => {
    // Initialize items array
    const items: any[] = [];

    // Add edit field option if allowed
    if (canEditColumn) {
        items.push(
            {
                label: t('dynamic-components:dropdownColumnOptions.editField'),
                key: `edit_${name}`,
            },
            {type: 'divider'}
        );
    }

    // Add sort options if the field is sortable
    if (!nonSortableColumns.includes(component)) {
        items.push(
            {
                label: t('dynamic-components:dropdownColumnOptions.sortAscending'),
                key: `asc_${name}`,
            },
            {
                label: t('dynamic-components:dropdownColumnOptions.sortDescending'),
                key: `desc_${name}`,
            },
            {type: 'divider'}
        );
    }

    // Add move options
    items.push(
        {
            label: t('dynamic-components:dropdownColumnOptions.moveLeft'),
            key: `left_${name}`,
            disabled: true,
        },
        {
            label: t('dynamic-components:dropdownColumnOptions.moveRight'),
            key: `right_${name}`,
            disabled: true,
        }
    );

    // Add remove field option if not the first column
    if (columnIndex !== 0 && canDeleteColumn && canEditColumn && !isTemplateField) {
        items.push(
            {type: 'divider'},
            {
                label: t('dynamic-components:dropdownColumnOptions.removeField'),
                danger: true,
                key: `remove_${name}`,
            }
        );
    }

    return items;
};
