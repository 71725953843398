import {AccountsEntity} from '@citrite/sf-api';
import {UsersEntity} from '@citrite/sf-api';

export const getAccounts = async (type?: string, searchTerm?: string) =>
    await AccountsEntity.getAddressBook(type, searchTerm).execute();

export const loadAssigneeUsers = async (userIds: string[]): Promise<any> => {
    try {
        const getUser = (x: string) => UsersEntity.get(x).execute();
        const usersList = await Promise.all(userIds.map(getUser));
        return usersList;
    } catch {
        throw new Error('Failed to Fetch User');
    }
};
