import {SyntheticEvent} from 'react';
import {Resizable, ResizeCallbackData} from 'react-resizable';

import {TableColumnRenderer} from '../DataTable';
import {ResizableHandle} from './TableHeader.styled';

interface ResizableTableTitleProps extends TableColumnRenderer {
    onResize: (e: SyntheticEvent<Element, Event>, data: ResizeCallbackData) => void;
    width: number;
}
export const ResizableTitle = (props: ResizableTableTitleProps) => {
    const {onResize, width, ...restProps} = props;

    if (!width) {
        return <th {...restProps} />;
    }

    return (
        <Resizable
            width={width}
            height={0}
            handle={
                <ResizableHandle
                    onClick={(mouseDragEvent: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                        mouseDragEvent.stopPropagation();
                    }}
                    role="presentation"
                />
            }
            onResize={onResize}
            draggableOpts={{enableUserSelectHack: false}}
        >
            <th {...restProps} />
        </Resizable>
    );
};
