import styled from '@emotion/styled';
import {sizes} from '@sharefiledev/antd-config';
import {Button, Typography} from 'antd';

export const SetOptionsLabel = styled(Typography.Text)`
    padding: 4px;
`;

export const SelectFieldOptions = styled.div`
    height: 160px;
    overflow: auto;
    padding: ${sizes.XS} ${sizes.MS} ${sizes.XS} ${sizes.XXS};
`;

export const AriaLive = styled.div`
    position: absolute;
    left: -9999px;
`;

export const AddOptionButton = styled(Button)`
    display: flex;
    justify-content: center;
`;
