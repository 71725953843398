import {recordSliceInitialState} from './recordSlice';
import {schemaSliceInitialState} from './schemaSlice';
import {ResetSlice, SliceStateCreator} from './types';
import {usersSliceInitialState} from './userSlice';

export const createResetSlice: SliceStateCreator<ResetSlice> = (set: any) => ({
    resetStore: () =>
        set({
            ...schemaSliceInitialState,
            ...usersSliceInitialState,
            ...recordSliceInitialState,
        }),
});
