import {Col, Popover, Row, Typography} from 'antd';
import {useEffect, useMemo, useState} from 'react';

import {
    createUniqueId,
    fetchCurrentField,
    transformNodeToText,
} from '../../../../schema-components/antd/edit/RichText/helper';
import RichTextEditor from '../../../../schema-components/antd/edit/RichText/index';
import {deepEqual} from '../../../../schema-components/antd/edit/RichText/plugIns/helper';
import {useRichText} from '../../context/RichText';
import {RichTextFieldProps} from '../Fields';

const {Text} = Typography;

export const RichTextInputField = (props: RichTextFieldProps) => {
    const {fieldValue, forwardedRef, record, name, onChange, onInlineUpdateCompletion} = props;
    const [visible, setVisible] = useState(false);
    const [renderText, setRenderText] = useState('');
    const {disableOnPopoverChange, textEditors} = useRichText();

    const handleOnChange = async (isOpen: boolean) => {
        const uniqueID = createUniqueId(name, record?._id);
        const {rtFieldValue} = fetchCurrentField(textEditors, uniqueID) ?? {};
        if (!rtFieldValue) return;
        const areObjectsEqual = deepEqual(fieldValue, rtFieldValue);
        if (!isOpen) {
            areObjectsEqual ? onInlineUpdateCompletion?.() : onChange(rtFieldValue);
        }
        setVisible(isOpen);
    };

    const fetchText = useMemo(() => {
        return () => {
            if (!fieldValue) return;
            const transformedStr = transformNodeToText(fieldValue);
            setRenderText(transformedStr);
        };
    }, [fieldValue]);

    useEffect(() => {
        fetchText();
        if (disableOnPopoverChange) return;
        setVisible(true);
        return () => setVisible(false);
    }, [fetchText]);

    const richTextProps = {...props, value: fieldValue};

    return (
        <Popover
            content={<RichTextEditor {...richTextProps} />}
            trigger="click"
            open={visible}
            destroyTooltipOnHide
            onOpenChange={!disableOnPopoverChange ? handleOnChange : undefined}
            afterOpenChange={(open) => open && forwardedRef.current.focus()}
        >
            <Row className="padded-row">
                <Col>
                    <Text ellipsis={{tooltip: ''}}>{renderText}</Text>
                </Col>
            </Row>
        </Popover>
    );
};
