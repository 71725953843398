import {useEffect} from 'react';

import {useAppContext} from '../../../AppContext';

export const usePluginEventListener = <T = any>(
    eventType: string,
    callback: (event: T) => void
) => {
    const {piletApi} = useAppContext();
    useEffect(() => {
        piletApi.on(eventType, callback);

        return () => {
            piletApi.off(eventType, callback);
        };
    }, [piletApi, eventType, callback]);
};
