import {CodeHighlightNode, CodeNode} from '@lexical/code';
import {AutoLinkNode, LinkNode} from '@lexical/link';
import {ListItemNode, ListNode} from '@lexical/list';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {HeadingNode, QuoteNode} from '@lexical/rich-text';
import {TableCellNode, TableNode, TableRowNode} from '@lexical/table';

import {FieldProperties} from '../../../../engine/types';
import defaultTheme from './theme/default';

const editorConfig = {
    namespace: '',
    // The editor theme
    theme: defaultTheme,
    // Handling of errors during update
    onError(error: Error) {
        throw error;
    },
    // Any custom nodes go here
    nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        CodeNode,
        CodeHighlightNode,
        TableNode,
        TableCellNode,
        TableRowNode,
        AutoLinkNode,
        LinkNode,
    ],
    // editorState: JSON.stringify(sampleData),
};

const withRichTextEditor = (ChildComponent: React.ComponentType<FieldProperties>) => {
    return (props: FieldProperties) => {
        return (
            <LexicalComposer initialConfig={editorConfig}>
                <ChildComponent {...props} />
            </LexicalComposer>
        );
    };
};

export default withRichTextEditor;
