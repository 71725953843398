import styled from '@emotion/styled';
import {colorPalette} from '@sharefiledev/antd-config';
import {Flex, Typography} from 'antd';

const {Text} = Typography;

export const HeaderFilterText = styled(Text)`
    color: ${colorPalette.neutral6};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 8px;
`;

export const FlexConatainer = styled(Flex)`
    @media only screen and (min-width: 650px) {
        max-width: 650px;
    }
    max-width: 350px;
    max-height: 504px;
    overflow: auto;
    padding: 8px 12px;
`;
