import {colorPalette} from '@sharefiledev/antd-config';

type Props = {
    open: boolean;
};

export const FilterIcon = ({open}: Props) => {
    return (
        <svg
            className="anticon"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="16"
            viewBox="0 0 16 14"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.5331 1.19811C14.541 1.07736 14.4986 0.958722 14.416 0.870331C14.3333 0.78194 14.2178 0.731671 14.0968 0.731445H1.87481C1.75374 0.731445 1.63811 0.781715 1.55543 0.870146C1.47275 0.958578 1.4304 1.07732 1.43847 1.19811C1.64931 4.13389 3.78839 6.57051 6.67214 7.15978V12.9202C6.6722 13.0899 6.77041 13.2443 6.9241 13.3162C7.07779 13.3882 7.25924 13.3648 7.38964 13.2562L9.13964 11.7979C9.23952 11.7149 9.29725 11.5917 9.29714 11.4619V7.15978C12.1819 6.57161 14.3222 4.13467 14.5331 1.19811Z"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{stroke: open ? colorPalette.lavender8 : '#171717'}} // Set stroke color dynamically using style attribute
            />
        </svg>
    );
};
