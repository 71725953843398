import {EditOutlined} from '@ant-design/icons';
import {$createCodeNode} from '@lexical/code';
import {$isLinkNode, TOGGLE_LINK_COMMAND} from '@lexical/link';
import {
    INSERT_CHECK_LIST_COMMAND,
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
    REMOVE_LIST_COMMAND,
} from '@lexical/list';
import {$createHeadingNode, $createQuoteNode} from '@lexical/rich-text';
import {$isAtNodeEnd} from '@lexical/selection';
import {$wrapNodes} from '@lexical/selection';
import {mergeRegister} from '@lexical/utils';
import {
    AlignCenter,
    AlignLeft,
    AlignRight,
    BulletList,
    CheckList,
    CodeBlock,
    Heading1,
    Heading2,
    Heading3,
    Justified,
    NormalFont,
    NumberedList,
    Quote,
} from '@sharefiledev/icons';
import {
    $createParagraphNode,
    $getSelection,
    $isRangeSelection,
    BaseSelection,
    ElementFormatType,
    FORMAT_ELEMENT_COMMAND,
    LexicalEditor,
    SELECTION_CHANGE_COMMAND,
} from 'lexical';
import {Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useRef, useState} from 'react';

import {useRichText} from '../../../../../layouts/table/context/RichText';
import {EditorValueType} from '../../../../../store/types';
import {t} from '../../../../../utils';
import {
    ActiveIcon,
    DividerWrapper,
    Dropdown,
    DropdownIcon,
    DropdownItem,
    DropdownText,
    Link,
    LinkEdit,
    LinkEditor,
    LinkInput,
} from '../SFLexicalRichTextEditor.styled';
import FontDropDown, {FontDropDownItem} from './DropDown';
import {EditorDropDownProp, ToolbarButtonProp} from './TextAreaField';

export const ToolbarButton = ({
    onClickEvent,
    isSelected,
    ariaLabel,
    icon,
    isDisabled,
    className,
    title,
}: ToolbarButtonProp) => (
    <button
        disabled={isDisabled}
        onClick={onClickEvent}
        className={`${className} "format" ` + (isSelected ? 'active' : '')}
        aria-label={ariaLabel}
        title={title}
    >
        {icon}
    </button>
);

export const supportedBlockTypes = new Set([
    'paragraph',
    'quote',
    'code',
    'h1',
    'h2',
    'h3',
    'bullet',
    'number',
    'check',
]);

export const blockTypeToBlockName = {
    bullet: 'Bulleted List',
    check: 'Check List',
    code: 'Code Block',
    h1: 'Heading 1',
    h2: 'Heading 2',
    h3: 'Heading 3',
    h4: 'Heading 4',
    h5: 'Heading 5',
    h6: 'Heading 6',
    number: 'Numbered List',
    paragraph: 'Normal',
    quote: 'Quote',
};

export const renderIcon = {
    bullet: <BulletList />,
    check: <CheckList />,
    code: <CodeBlock />,
    h1: <Heading1 />,
    h2: <Heading2 />,
    h3: <Heading3 />,
    h4: <Heading3 />,
    h5: <Heading3 />,
    h6: <Heading3 />,
    number: <NumberedList />,
    paragraph: <NormalFont />,
    quote: <Quote />,
};

export const Divider = () => {
    return <DividerWrapper />;
};

export const LowPriority = 1;

export const positionEditorElement = (
    editor: any,
    rect: {top: any; height: any; left: number; width: number} | null
) => {
    if (rect === null) {
        editor.style.opacity = '0';
        editor.style.top = '-1000px';
        editor.style.left = '-1000px';
    } else {
        editor.style.opacity = '1';
        editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`;
        editor.style.left = `${
            rect.left + window.pageXOffset - editor.offsetWidth / 2 + rect.width / 2
        }px`;
    }
};

export const getSelectedNode = (selection: any) => {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
        return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
        return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    }
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
};

export const FloatingLinkEditor = ({editor, isExpanded}: any) => {
    const editorRef = useRef(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const mouseDownRef = useRef(false);
    const [linkUrl, setLinkUrl] = useState('');
    const [isEditMode, setEditMode] = useState(false);
    const [lastSelection, setLastSelection] = useState<BaseSelection | null>(null);
    const {setDisableOnPopoverChange} = useRichText();

    const updateLinkEditor = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent)) {
                setLinkUrl(parent.getURL());
            } else if ($isLinkNode(node)) {
                setLinkUrl(node.getURL());
            } else {
                setLinkUrl('');
            }
        }
        const editorElem = editorRef.current;
        const nativeSelection = window.getSelection();
        const activeElement = document.activeElement;

        if (editorElem === null) {
            setDisableOnPopoverChange(false);
            return;
        }

        const rootElement = editor.getRootElement();
        if (
            selection !== null &&
            !nativeSelection?.isCollapsed &&
            rootElement !== null &&
            rootElement.contains(nativeSelection?.anchorNode)
        ) {
            if (!isExpanded) {
                setDisableOnPopoverChange(true);
            }
            const domRange = nativeSelection?.getRangeAt(0);
            let rect;
            if (nativeSelection?.anchorNode === rootElement) {
                let inner = rootElement;
                while (inner.firstElementChild != null) {
                    inner = inner.firstElementChild;
                }
                rect = inner.getBoundingClientRect();
            } else {
                rect = domRange?.getBoundingClientRect();
            }

            if (!mouseDownRef.current) {
                positionEditorElement(editorElem, rect);
            }
            setLastSelection(selection);
        } else if (!activeElement || activeElement.className !== 'link-input') {
            if (!isExpanded) {
                setDisableOnPopoverChange(false);
            }
            positionEditorElement(editorElem, null);
            setLastSelection(null);
            setEditMode(false);
            setLinkUrl('');
        }

        return true;
    }, [editor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({editorState}: any) => {
                editorState.read(() => {
                    updateLinkEditor();
                });
            }),

            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    updateLinkEditor();
                    return true;
                },
                LowPriority
            )
        );
    }, [editor, updateLinkEditor]);

    useEffect(() => {
        editor.getEditorState().read(() => {
            updateLinkEditor();
        });
    }, [editor, updateLinkEditor]);

    useEffect(() => {
        if (isEditMode && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isEditMode]);

    return (
        <LinkEditor ref={editorRef}>
            {isEditMode ? (
                <LinkInput
                    ref={inputRef}
                    value={linkUrl}
                    onChange={(event) => {
                        setLinkUrl(event.target.value);
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            event.preventDefault();
                            if (lastSelection !== null) {
                                if (linkUrl !== '') {
                                    editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                                }
                                setEditMode(false);
                            }
                        } else if (event.key === 'Escape') {
                            event.preventDefault();
                            setEditMode(false);
                        }
                    }}
                />
            ) : (
                <>
                    <Link href={linkUrl} target="_blank" rel="noopener noreferrer">
                        {linkUrl}
                    </Link>
                    <LinkEdit
                        role="button"
                        tabIndex={0}
                        onMouseDown={(event) => event.preventDefault()}
                        onClick={() => {
                            setEditMode(true);
                        }}
                        onKeyDown={() => {
                            setEditMode(true);
                        }}
                    >
                        <EditOutlined />
                    </LinkEdit>
                </>
            )}
        </LinkEditor>
    );
};

export const EditorDropDownItem = ({icon, isSelected, handleOnClick, text}: EditorDropDownProp) => (
    <DropdownItem onClick={handleOnClick} isTextAlign={false}>
        <DropdownIcon>{icon}</DropdownIcon>
        <DropdownText>{text}</DropdownText>
        {isSelected && <ActiveIcon />}
    </DropdownItem>
);

export const formatParagraph = (
    blockTypeName: string,
    editor: any,
    setShowBlockOptionsDropDown: Dispatch<SetStateAction<boolean>>
) => {
    if (blockTypeName !== 'paragraph') {
        editor.update(() => {
            const selection = $getSelection();

            if ($isRangeSelection(selection)) {
                $wrapNodes(selection, () => $createParagraphNode());
            }
        });
    }
    setShowBlockOptionsDropDown(false);
};

export const formatLargeHeading = (
    blockTypeName: string,
    editor: any,
    setShowBlockOptionsDropDown: Dispatch<SetStateAction<boolean>>
) => {
    if (blockTypeName !== 'h1') {
        editor.update(() => {
            const selection = $getSelection();

            if ($isRangeSelection(selection)) {
                $wrapNodes(selection, () => $createHeadingNode('h1'));
            }
        });
    }
    setShowBlockOptionsDropDown(false);
};

export const formatCode = (
    blockTypeName: string,
    editor: any,
    setShowBlockOptionsDropDown: Dispatch<SetStateAction<boolean>>
) => {
    if (blockTypeName !== 'code') {
        editor.update(() => {
            const selection = $getSelection();

            if ($isRangeSelection(selection)) {
                $wrapNodes(selection, () => $createCodeNode());
            }
        });
    }
    setShowBlockOptionsDropDown(false);
};

export const formatQuote = (
    blockTypeName: string,
    editor: any,
    setShowBlockOptionsDropDown: Dispatch<SetStateAction<boolean>>
) => {
    if (blockTypeName !== 'quote') {
        editor.update(() => {
            const selection = $getSelection();

            if ($isRangeSelection(selection)) {
                $wrapNodes(selection, () => $createQuoteNode());
            }
        });
    }
    setShowBlockOptionsDropDown(false);
};

export const formatNumberedList = (
    blockTypeName: string,
    editor: any,
    setShowBlockOptionsDropDown: Dispatch<SetStateAction<boolean>>
) => {
    if (blockTypeName !== 'ol') {
        editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    } else {
        editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
    setShowBlockOptionsDropDown(false);
};

export const formatSmallHeading = (
    blockTypeName: string,
    editor: any,
    setShowBlockOptionsDropDown: Dispatch<SetStateAction<boolean>>
) => {
    if (blockTypeName !== 'h2') {
        editor.update(() => {
            const selection = $getSelection();

            if ($isRangeSelection(selection)) {
                $wrapNodes(selection, () => $createHeadingNode('h2'));
            }
        });
    }
    setShowBlockOptionsDropDown(false);
};

export const formatVerySmallHeading = (
    blockTypeName: string,
    editor: any,
    setShowBlockOptionsDropDown: Dispatch<SetStateAction<boolean>>
) => {
    if (blockTypeName !== 'h3') {
        editor.update(() => {
            const selection = $getSelection();

            if ($isRangeSelection(selection)) {
                $wrapNodes(selection, () => $createHeadingNode('h3'));
            }
        });
    }
    setShowBlockOptionsDropDown(false);
};

export const formatBulletList = (
    blockTypeName: string,
    editor: any,
    setShowBlockOptionsDropDown: Dispatch<SetStateAction<boolean>>
) => {
    if (blockTypeName !== 'ul') {
        editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    } else {
        editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
    setShowBlockOptionsDropDown(false);
};

const formatCheckList = (
    blockTypeName: string,
    editor: any,
    setShowBlockOptionsDropDown: Dispatch<SetStateAction<boolean>>
) => {
    if (blockTypeName !== 'check') {
        editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
    }
    setShowBlockOptionsDropDown(false);
};

export const BlockOptionsDropdownList = ({
    editor,
    blockType,
    toolbarRef,
    setShowBlockOptionsDropDown,
}: any) => {
    const dropDownRef = useRef(null);

    const {setDisableOnPopoverChange} = useRichText();
    useEffect(() => {
        const toolbar = toolbarRef.current;
        const dropDown: any = dropDownRef.current;

        if (toolbar !== null && dropDown !== null) {
            const {top, left} = toolbar.getBoundingClientRect();
            dropDown.style.top = `${top + 40}px`;
            dropDown.style.left = `${left}px`;
        }
    }, [dropDownRef, toolbarRef]);

    useEffect(() => {
        const dropDown: any = dropDownRef.current;
        const toolbar = toolbarRef.current;

        const handle = (event: {target: any}) => {
            const target = event.target;

            if (!dropDown?.contains(target) && !toolbar?.contains(target)) {
                setShowBlockOptionsDropDown(false);
                setDisableOnPopoverChange(false);
            }
        };
        document.addEventListener('click', handle);

        return () => {
            setDisableOnPopoverChange(false);
            document.removeEventListener('click', handle);
        };
    }, [dropDownRef, setShowBlockOptionsDropDown, toolbarRef]);

    return (
        <Dropdown ref={dropDownRef}>
            <EditorDropDownItem
                icon={renderIcon.paragraph}
                isSelected={blockType === 'paragraph'}
                handleOnClick={() =>
                    formatParagraph(blockType, editor, setShowBlockOptionsDropDown)
                }
                text={t('dynamic-components:richText.format.normal')}
            />

            <EditorDropDownItem
                icon={renderIcon.h1}
                isSelected={blockType === 'h1'}
                handleOnClick={() =>
                    formatLargeHeading(blockType, editor, setShowBlockOptionsDropDown)
                }
                text={t('dynamic-components:richText.format.LargeHeading')}
            />
            <EditorDropDownItem
                icon={renderIcon.h2}
                isSelected={blockType === 'h2'}
                handleOnClick={() =>
                    formatSmallHeading(blockType, editor, setShowBlockOptionsDropDown)
                }
                text={t('dynamic-components:richText.format.smallHeading')}
            />
            <EditorDropDownItem
                icon={renderIcon.h3}
                isSelected={blockType === 'h3'}
                handleOnClick={() =>
                    formatVerySmallHeading(blockType, editor, setShowBlockOptionsDropDown)
                }
                text={t('dynamic-components:richText.format.smallestHeading')}
            />
            <EditorDropDownItem
                icon={renderIcon.bullet}
                isSelected={blockType === 'ul'}
                handleOnClick={() =>
                    formatBulletList(blockType, editor, setShowBlockOptionsDropDown)
                }
                text={t('dynamic-components:richText.format.bulletList')}
            />
            <EditorDropDownItem
                icon={renderIcon.number}
                isSelected={blockType === 'ol'}
                handleOnClick={() =>
                    formatNumberedList(blockType, editor, setShowBlockOptionsDropDown)
                }
                text={t('dynamic-components:richText.format.numberList')}
            />
            <EditorDropDownItem
                icon={renderIcon.quote}
                isSelected={blockType === 'quote'}
                handleOnClick={() => formatQuote(blockType, editor, setShowBlockOptionsDropDown)}
                text={t('dynamic-components:richText.format.quote')}
            />
            <EditorDropDownItem
                icon={renderIcon.code}
                isSelected={blockType === 'code'}
                handleOnClick={() => formatCode(blockType, editor, setShowBlockOptionsDropDown)}
                text={t('dynamic-components:richText.format.codeBlock')}
            />
            <EditorDropDownItem
                icon={renderIcon.check}
                isSelected={blockType === 'check'}
                handleOnClick={() =>
                    formatCheckList(blockType, editor, setShowBlockOptionsDropDown)
                }
                text={t('dynamic-components:richText.format.checkList')}
            />
        </Dropdown>
    );
};

const ELEMENT_FORMAT_OPTIONS: {
    [key in Exclude<ElementFormatType, ''>]: {
        value: string;
        name: string;
    };
} = {
    center: {
        value: 'center',
        name: t('dynamic-components:richText.areaLabel.center'),
    },
    justify: {
        value: 'justify',
        name: t('dynamic-components:richText.areaLabel.justify'),
    },
    left: {
        value: 'left',
        name: t('dynamic-components:richText.areaLabel.left'),
    },
    right: {
        value: 'right',
        name: t('dynamic-components:richText.areaLabel.right'),
    },
    start: {
        value: '',
        name: '',
    },
    end: {
        value: '',
        name: '',
    },
};

export const renderFontIcon: Record<string, ReactNode> = {
    center: <AlignCenter />,
    justify: <Justified />,
    left: <AlignLeft />,
    right: <AlignRight />,
};

export const ElementFormatDropdown = ({
    editor,
    value,
    disabled = false,
}: {
    editor: LexicalEditor;
    value: ElementFormatType;
    disabled: boolean;
}) => {
    const formatOption = ELEMENT_FORMAT_OPTIONS[value || 'left'];

    return (
        <FontDropDown
            disabled={disabled}
            buttonLabel={formatOption.name}
            buttonIconName={formatOption.value}
            buttonClassName="toolbar-item spaced alignment"
            buttonAriaLabel={t('dynamic-components:richText.areaLabel.textAlignment')}
        >
            <FontDropDownItem
                onClick={() => {
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
                }}
                className="item"
            >
                <DropdownIcon>{renderFontIcon.left}</DropdownIcon>
                <DropdownText>{t('dynamic-components:richText.areaLabel.left')}</DropdownText>
            </FontDropDownItem>
            <FontDropDownItem
                onClick={() => {
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
                }}
                className="item"
            >
                <DropdownIcon>{renderFontIcon.center}</DropdownIcon>
                <DropdownText>{t('dynamic-components:richText.areaLabel.center')}</DropdownText>
            </FontDropDownItem>
            <FontDropDownItem
                onClick={() => {
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
                }}
                className="item"
            >
                <DropdownIcon>{renderFontIcon.right}</DropdownIcon>
                <DropdownText>{t('dynamic-components:richText.areaLabel.right')}</DropdownText>
            </FontDropDownItem>
            <FontDropDownItem
                onClick={() => {
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
                }}
                className="item"
            >
                <DropdownIcon>{renderFontIcon.justify}</DropdownIcon>
                <DropdownText>{t('dynamic-components:richText.areaLabel.justify')}</DropdownText>
            </FontDropDownItem>
        </FontDropDown>
    );
};

// A method to compare 2 obj whose key are not undefined or null
export const deepEqual = (obj1: any, obj2: any) => {
    if (obj1 === obj2 || (obj1 === null && obj2 === null)) {
        return true;
    }

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
        return false;
    }

    const keysA = Object.keys(obj1 || {}).filter((key) => obj1[key]);
    const keysB = Object.keys(obj2 || {}).filter((key) => obj2[key]);

    if (keysA.length !== keysB.length) {
        return false;
    }

    for (const key of keysA) {
        if (
            obj1[key] === undefined ||
            obj2[key] === undefined ||
            obj1[key] === null ||
            obj2[key] === null
        ) {
            continue;
        }

        if (!deepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
};

export const updateTextEditorFields = (
    prevState: EditorValueType[],
    uniqueID: string,
    value: Record<string, any>
) => {
    const existingIndex = prevState.findIndex(
        (item: EditorValueType) => item.rtFieldName == uniqueID
    );
    if (existingIndex !== -1) {
        const updatedState = [...prevState];
        updatedState[existingIndex] = {
            ...updatedState[existingIndex],
            rtFieldValue: value,
        };
        return updatedState;
    } else {
        return [...prevState, {rtFieldName: uniqueID, rtFieldValue: value}];
    }
};
