import styled from '@emotion/styled';

export const FontSizeWrapper = styled.div`
    display: flex;
    .font-size-input {
        font-weight: 400;
        font-size: 14px;
        color: #777;
        border-radius: 5px;
        border: 2px solid #bcc0c2;
        height: 18px;
        margin-top: 5px;
        padding: 2px 4px;
        text-align: center;
        width: 18px;
    }

    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }

    .add-icon {
        background-repeat: no-repeat;
        background-position: center;
    }

    .minus-icon {
        background-repeat: no-repeat;
        background-position: center;
    }

    button.font-decrement {
        padding: 0px;
        margin-right: 3px;
    }

    button.font-increment {
        padding: 0px;
        margin-left: 3px;
    }
`;
