import axios from 'axios';

import {API_PATH} from '../constants/constants';

const apiClient = axios.create({
    baseURL: window.origin + API_PATH,
    headers: {
        'X-BFF-CSRF': 'true',
    },
});

apiClient.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        if (error.response) {
            throw new Error(error.response.data.message);
        } else if (error.request) {
            throw new Error('No response received');
        } else {
            throw new Error('Request setup error');
        }
    }
);

export default apiClient;
