import styled from '@emotion/styled';
import {colorPalette} from '@sharefiledev/antd-config';
import {CSSProperties} from 'react';

const CONTAINER_HEIGHT = '214px !important';
const WHITE_BG = colorPalette.neutral1;
const BORDER_COLOR = colorPalette.neutral4;
const GREY_COLOR = '#777';
const LIGHT_GREY = '#EEEEEE';
const DARK_GREY = '#444';
const CONCRETE_GREY = '#f0f2f5';

export const MainContainer = styled.div<{isFullscreen: boolean}>`
    & .editor-inner {
        background: ${WHITE_BG};
        position: relative;
        border: 2px solid ${BORDER_COLOR};
        border-top: 0;
        border-radius: ${(prop) => (prop.isFullscreen ? '0' : '0 0 10px 10px')};
    }

    & .editor-input {
        min-height: ${CONTAINER_HEIGHT};
        resize: none;
        font-size: 15px;
        caret-color: rgb(5, 5, 5);
        position: relative;
        tab-size: 1;
        outline: 0;
        padding: 5px 10px;
        caret-color: ${DARK_GREY};
        max-height: ${(prop) => (prop.isFullscreen ? 'none' : CONTAINER_HEIGHT)};
        overflow-y: auto;
    }

    & .toolbar {
        display: flex;
        background: ${WHITE_BG};
        padding: 4px;
        border: 2px solid ${BORDER_COLOR};
        border-radius: 10px 10px 0 0;
        @media (max-width: 768px) {
            flex-flow: wrap;
        }
        overflow: auto;
        scrollbar-width: none;
    }

    & .toolbar button.toolbar-item {
        border: 0;
        display: flex;
        background: none;
        border-radius: 10px;
        padding: 8px;
        cursor: pointer;
        align-items: center;
        height: 2.57rem;
        margin-left: 4px;
    }

    & .toolbar button.toolbar-item:disabled {
        cursor: not-allowed;
    }

    & .toolbar button.toolbar-item.spaced {
        margin-right: 0px;
    }

    & .toolbar button.toolbar-item i.format {
        background-size: contain;
        display: inline-block;
        height: 18px;
        width: 18px;
        margin-top: 2px;
        vertical-align: -0.25em;
        display: flex;
        opacity: 0.6;
    }

    & .toolbar-item-disabled {
        opacity: 0.2;
    }

    & .toolbar button.toolbar-item.active {
        background-color: ${colorPalette.lavender1};
    }

    & .toolbar button.toolbar-item.active i {
        opacity: 1;
    }

    & .toolbar .toolbar-item:hover:not([disabled]) {
        background-color: ${LIGHT_GREY};
    }

    & .toolbar select.toolbar-item {
        border: 0;
        display: flex;
        background: none;
        border-radius: 10px;
        padding: 0.25rem;
        vertical-align: middle;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 70px;
        font-size: 14px;
        color: ${GREY_COLOR};
        text-overflow: ellipsis;
    }

    & .toolbar select.code-language {
        text-transform: capitalize;
        width: 100px;
    }

    & .toolbar .toolbar-item .text {
        display: flex;
        line-height: 20px;
        width: 200px;
        vertical-align: middle;
        font-size: 12px;
        color: ${GREY_COLOR};
        text-overflow: ellipsis;
        width: 66px;
        overflow: hidden;
        height: 20px;
        text-align: left;
    }

    & .toolbar .toolbar-item .text-alignment {
        display: flex;
        line-height: 20px;
        width: 200px;
        vertical-align: middle;
        font-size: 12px;
        color: ${GREY_COLOR};
        text-overflow: ellipsis;
        width: 40px;
        overflow: hidden;
        height: 20px;
        text-align: left;
    }

    & .toolbar .toolbar-item .icon {
        display: flex;
        width: 20px;
        height: 20px;
        user-select: none;
        line-height: 16px;
        background-size: contain;
    }

    & .other h2 {
        font-size: 18px;
        color: ${DARK_GREY};
        margin-bottom: 7px;
    }

    & .other a {
        color: ${GREY_COLOR};
        text-decoration: underline;
        font-size: 14px;
    }

    & .other ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    & .App {
        font-family: sans-serif;
        text-align: center;
    }

    h1 {
        font-size: 24px;
        color: #333;
    }

    & .ltr {
        text-align: left;
    }

    & .rtl {
        text-align: right;
    }

    & .editor-container {
        border-radius: 2px;
        max-width: ${(prop) => (prop.isFullscreen ? 'none' : '652px !important')};
        color: #000;
        position: relative;
        line-height: 1.7;
        font-weight: 400;
        text-align: left;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    & .editor-placeholder {
        color: #999;
        overflow: hidden;
        position: absolute;
        text-overflow: ellipsis;
        top: 5px;
        left: 10px;
        font-size: 15px;
        user-select: none;
        display: inline-block;
        pointer-events: none;
    }

    & .editor-text-bold {
        font-weight: bold;
    }

    & .editor-text-italic {
        font-style: italic;
    }

    & .editor-text-underline {
        text-decoration: underline;
    }

    & .editor-text-strikethrough {
        text-decoration: line-through;
    }

    & .editor-text-underlineStrikethrough {
        text-decoration: underline line-through;
    }

    & .editor-text-code {
        background-color: ${CONCRETE_GREY};
        padding: 1px 0.25rem;
        font-family: Menlo, Consolas, Monaco, monospace;
        font-size: 94%;
    }

    & .editor-link {
        color: ${colorPalette.lavender6};
        text-decoration: none;
    }

    & .editor-code {
        background-color: ${CONCRETE_GREY};
        font-family: Menlo, Consolas, Monaco, monospace;
        display: block;
        padding: 8px 8px 8px 52px;
        line-height: 1.53;
        font-size: 13px;
        margin: 0;
        margin-top: 8px;
        margin-bottom: 8px;
        tab-size: 2;
        /* white-space: pre; */
        overflow-x: auto;
        position: relative;

        &:before {
            content: attr(data-gutter);
            position: absolute;
            background-color: ${LIGHT_GREY};
            left: 0;
            top: 0;
            border-right: 1px solid #ccc;
            padding: 8px;
            color: ${GREY_COLOR};
            white-space: pre-wrap;
            text-align: right;
            min-width: 25px;
        }

        &:after {
            content: attr(data-highlight-language);
            top: 0;
            right: 3px;
            padding: 3px;
            font-size: 10px;
            text-transform: uppercase;
            position: absolute;
            color: rgba(0, 0, 0, 0.5);
        }
    }

    & .editor-tokenComment {
        color: slategray;
    }

    & .editor-tokenPunctuation {
        color: #999;
    }

    & .editor-tokenProperty {
        color: #905;
    }

    & .editor-tokenSelector {
        color: #690;
    }

    & .editor-tokenOperator {
        color: #9a6e3a;
    }

    & .editor-tokenAttr {
        color: #07a;
    }

    & .editor-tokenVariable {
        color: #e90;
    }

    & .editor-tokenFunction {
        color: #dd4a68;
    }

    & .editor-paragraph {
        margin: 0;
        margin-bottom: 8px;
        position: relative;
    }

    & .editor-paragraph:last-child {
        margin-bottom: 0;
    }

    & .editor-heading-h1 {
        font-size: 24px;
        color: rgb(5, 5, 5);
        font-weight: 400;
        margin: 0;
        margin-bottom: 12px;
        padding: 0;
    }

    & .editor-heading-h2 {
        font-size: 15px;
        color: rgb(101, 103, 107);
        font-weight: 700;
        margin: 0;
        margin-top: 10px;
        padding: 0;
        text-transform: uppercase;
    }

    & .editor-quote {
        margin: 0;
        margin-left: 20px;
        font-size: 15px;
        color: rgb(101, 103, 107);
        border-left-color: rgb(206, 208, 212);
        border-left-width: 4px;
        border-left-style: solid;
        padding-left: 16px;
    }

    & .editor-list-ol {
        padding: 0;
        margin: 0;
        margin-left: 16px;
    }

    & .editor-list-ul {
        padding: 0;
        margin: 0;
        margin-left: 16px;
    }

    .editor-listitem {
        margin: 8px 32px 8px 32px;
    }

    & .editor-listItemChecked,
    .editor-listItemUnchecked {
        position: relative;
        margin-left: 8px;
        margin-right: 8px;
        padding-left: 24px;
        padding-right: 24px;
        list-style-type: none;
        outline: none;
    }

    .editor-listItemChecked {
        text-decoration: line-through;
    }

    & .editor-listItemUnchecked:before,
    .editor-listItemChecked:before {
        content: '';
        width: 15px;
        height: 15px;
        top: 4px;
        left: 1px;
        cursor: pointer;
        display: block;
        background-size: cover;
        position: absolute;
    }

    & .editor-listItemUnchecked[dir='rtl']:before,
    .editor-listItemChecked[dir='rtl']:before {
        left: auto;
        right: 0;
    }

    & .editor-listItemUnchecked:focus:before,
    .editor-listItemChecked:focus:before {
        box-shadow: 0 0 0 2px #a6cdfe;
        border-radius: 2px;
    }

    & .editor-listItemUnchecked:before {
        border: 1px solid #999;
        border-radius: 2px;
    }

    & .editor-listItemChecked:before {
        border: 1px solid ${colorPalette.lavender6};
        border-radius: 2px;
        background-color: ${colorPalette.lavender6};
        background-repeat: no-repeat;
    }

    & .editor-listItemChecked:after {
        content: '';
        cursor: pointer;
        border-color: #fff;
        border-style: solid;
        position: absolute;
        display: block;
        top: 8px;
        width: 3px;
        left: 7px;
        right: 7px;
        height: 6px;
        transform: rotate(45deg);
        border-width: 0 2px 2px 0;
    }

    & .editor-nested-listitem {
        list-style-type: none;
    }

    &pre::-webkit-scrollbar {
        background: transparent;
        width: 10px;
    }

    &pre::-webkit-scrollbar-thumb {
        background: #999;
    }

    & .debug-timetravel-panel {
        overflow: hidden;
        padding: 0 0 10px 0;
        margin: auto;
        display: flex;
    }

    & .debug-timetravel-panel-slider {
        padding: 0;
        flex: 8;
    }

    & .debug-timetravel-panel-button {
        padding: 0;
        border: 0;
        background: none;
        flex: 1;
        color: #fff;
        font-size: 12px;
    }

    & .debug-timetravel-panel-button:hover {
        text-decoration: underline;
    }

    & .debug-timetravel-button {
        border: 0;
        padding: 0;
        font-size: 12px;
        top: 10px;
        right: 15px;
        position: absolute;
        background: none;
        color: #fff;
    }

    & .debug-timetravel-button:hover {
        text-decoration: underline;
    }

    & .emoji {
        color: transparent;
        background-size: 16px 16px;
        background-position: center;
        background-repeat: no-repeat;
        vertical-align: middle;
        margin: 0 -1px;
    }

    & .emoji-inner {
        padding: 0 0.15em;
    }

    & .emoji-inner::selection {
        color: transparent;
        background-color: rgba(150, 150, 150, 0.4);
    }

    & .emoji-inner::moz-selection {
        color: transparent;
        background-color: rgba(150, 150, 150, 0.4);
    }
`;

export const PlaceHolderContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px;
`;

export const ContentEditableStyles: CSSProperties = {
    position: 'relative',
    borderColor: 'rgba(255,211,2,0.68)',
    border: '2px solid rgb(215, 217, 219)',
    borderRadius: '5px',
    maxWidth: '100%',
    padding: '8px',
};

export const Dropdown = styled.div`
    z-index: 2001;
    display: block;
    position: absolute !important;
    box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
        inset 0 0 0 1px rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    min-width: 100px;
    min-height: 40px;
    background-color: #fff;
`;

export const DropdownItem = styled.button<{isTextAlign: boolean}>`
    margin: 0 8px 0 8px;
    padding: 8px;
    color: #050505;
    cursor: pointer;
    line-height: 16px;
    font-size: 15px;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-shrink: 0;

    justify-content: space-between;
    background-color: #fff;
    border-radius: 8px;
    border: 0;
    width: ${(prop) => (prop.isTextAlign ? '140px' : '200px')};
    min-width: ${(prop) => (prop.isTextAlign ? '140px' : '200px')};

    &:hover {
        background-color: ${LIGHT_GREY};
    }

    &:first-of-type {
        margin: 8px 8px 0px 8px;
    }
    &:last-of-type {
        margin: 0px 8px 8px 8px;
    }
`;

export const ActiveIcon = styled.span`
    display: flex;
    width: 20px;
    height: 20px;
    background-size: contain;
`;

export const DropdownText = styled.span`
    display: flex;
    line-height: 20px;
    flex-grow: 1;
    width: 200px;
`;

export const DividerWrapper = styled.div`
    width: 1px;
    background-color: #bcc0c2;
    margin: 0 3px;
    padding: 1px;
    height: 24px;
    align-self: center;
`;

export const DropdownIcon = styled.span`
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 12px;
    line-height: 16px;
    background-size: contain;
`;

export const LinkEditor = styled.div`
    position: absolute;
    z-index: 20001;
    top: -10000px;
    left: -10000px;
    margin-top: -6px;
    max-width: 300px;
    width: 100%;
    opacity: 0;
    background-color: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    transition: opacity 0.5s;
`;

export const LinkInput = styled.input`
    width: calc(100% - 24px);
    box-sizing: border-box;
    margin: 8px 12px;
    padding: 8px 12px;
    border-radius: 15px;
    background-color: ${LIGHT_GREY};
    font-size: 15px;
    color: rgb(5, 5, 5);
    border: 0;
    outline: 0;
    position: relative;
    font-family: inherit;
`;

export const LinkEdit = styled.div`
    padding: 8px 0;
    background-position: center;
    background-repeat: no-repeat;
    width: 35px;
    align-content: center;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
`;

export const Link = styled.a`
    color: ${colorPalette.lavender6};
    text-decoration: none;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 30px;
    text-overflow: ellipsis;
    padding: 12px;

    &:hover {
        text-decoration: underline;
    }
`;

export const Button = styled.button`
    width: 20px;
    height: 20px;
    display: inline-block;
    padding: 6px;
    border-radius: 8px;
    cursor: pointer;
    margin: 0 2px;

    &.hovered {
        background-color: ${LIGHT_GREY};
    }
`;

export const Icon = styled.i`
    background-size: contain;
    display: inline-block;
    height: 20px;
    width: 20px;
    vertical-align: -0.25em;
`;

export const FooterWrapper = styled.div`
    padding: 0.5rem;
    border-top: 2px solid #bcc0c2;
    height: 2.25rem;
    align-content: center;
    display: flex;
    flex-wrap: nowrap;
    font-size: 13px;
    align-items: center;

    & .toolbar-item {
        border: 0;
        display: flex;
        background: none;
        border-radius: 10px;
        padding: 8px;
        cursor: pointer;
        align-items: center;
        gap: 8px;
        height: 2.57rem;
    }

    & .toolbar-item:hover:not([disabled]) {
        background-color: ${LIGHT_GREY};
    }
    span {
        color: #171717;
        font-weight: 400;
    }
`;

export const ExpandedModal = styled.div`
    & .editor-input {
        height: calc(100vh - 200px);
    }
`;

export const FooterWithinModal = styled.div`
    padding: 0.5rem;
    border-left: 2px solid #bcc0c2;
    border-right: 2px solid #bcc0c2;
    border-bottom: 2px solid #bcc0c2;
    border-radius: 0 0 10px 10px;
    height: 2.25rem;
    display: flex;
    flex-wrap: nowrap;
    font-size: 13px;
    align-items: center;

    & .toolbar-item {
        border: 0;
        display: flex;
        background: none;
        border-radius: 10px;
        padding: 8px;
        cursor: pointer;
        align-items: center;
        gap: 8px;
        height: 2.57rem;
    }

    & .toolbar-item:hover:not([disabled]) {
        background-color: ${LIGHT_GREY};
    }

    span {
        color: #171717;
        font-weight: 400;
    }
`;

export const RichTextTableCellWrapper = styled.div``;
